@import "../../mixins";

.recoverForm {
  width: 100%;
  height: 100%;
  .redirect {
    width: 100%;
    height: 100vh;
    background-color: #fff;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 24px;
    text-align: center;
    .infoText {
      font-size: 20px;
      line-height: 1.5;
      color: #23272f;
    }
    p {
      line-height: 1.5;
      color: #535861;
      .timer {
        color: #115eed;
        font-weight: 500;
      }
      a {
        color: #115eed;
        font-weight: 500;
      }
    }
  }
  .formTitle {
    font-weight: 500;
    font-size: 32px;
    color: #23272f;
    margin-bottom: 16px;
  }
  .question {
    color: #535861;
    margin-bottom: 32px;
    a {
      color: #115eed;
      font-weight: 500;
      transition: 0.3s ease;
      &:hover {
        opacity: 0.8;
      }
    }
  }
  .phoneBox {
    width: 100%;
    label {
      display: inline-block;
      font-size: 14px;
      color: #535861;
      margin-bottom: 12px;
    }
    .PhoneInput {
      .PhoneInputInput {
        @include formInput();
        font-size: 16px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        &.err {
          border-color: #ed4611;
        }
      }
      .PhoneInputCountry {
        background: #f2f4f8;
        margin-right: 0;
        padding: 10px;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }
    }
  }
  .inputBox {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin: 32px 0;
    position: relative;
    label {
      font-size: 14px;
      color: #535861;
    }
    .formInput {
      @include formInput();
      font-size: 16px;
      &.err {
        border-color: #ed4611;
      }
    }
    .errorAlert {
      font-size: 14px;
      color: #ed4611;
      position: relative;
      padding-left: 26px;
      &::before {
        content: "";
        background-image: url(../../../images/icons/alert.svg);
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain;
        width: 20px;
        height: 20px;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
    .eye {
      position: absolute;
      top: 50px;
      right: 16px;
      cursor: pointer;
    }
  }
  .errorAlert {
    font-size: 14px;
    color: #ed4611;
    position: relative;
    padding-left: 26px;
    &::before {
      content: "";
      background-image: url(../../../images/icons/alert.svg);
      background-repeat: no-repeat;
      background-position: center center;
      background-size: contain;
      width: 20px;
      height: 20px;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  .formBtn {
    margin-top: 32px;
    @include formBtn();
  }
}

@media (max-width: 768px) {
  .recoverForm {
    .socialBtns {
      width: 100%;
      flex-wrap: wrap;
      a {
        width: 100%;
      }
    }
  }
}

@media (max-width: 368px) {
  .recoverForm {
    .formTitle {
      font-size: 24px;
    }
    .question {
      font-size: 14px;
    }
  }
}
