.paymentInfo {
  .container {
    .row {
      height: 87vh;
      .infoCard {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 16;
        .success {
          color: #27e574;
        }
        .cancel {
          color: #ed4611;
        }
        .decline {
          color: #ed4690;
        }
        p {
          a {
            color: #115eed;
          }
        }
      }
    }
  }
}

@media (max-width: 544px) {
  .paymentInfo {
    .container {
      .row {
        text-align: center;
        padding-left: 16px;
        padding-right: 16px;
        .infoCard {
          h2 {
            font-size: 18px;
          }
          p {
            font-size: 14px;
          }
        }
      }
    }
  }
}
