@import "../../mixins";

.registerForm {
  width: 100%;
  height: 100%;
  .formTitle {
    font-weight: 500;
    font-size: 32px;
    color: #23272f;
    margin-bottom: 16px;
  }
  .question {
    color: #535861;
    margin-bottom: 0;
    span {
      font-weight: 500;
      color: #115eed;
      &.reSend {
        cursor: pointer;
        margin: 0 10px;
      }
      &.timer {
        margin: 0 10px;
      }
    }
  }
  .inputBox {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin: 32px 0;
    label {
      font-size: 14px;
      color: #535861;
    }
    .formInput {
      @include formInput();
      &.error {
        border-color: #ed4611;
      }
    }
    .errorAlert {
      font-size: 14px;
      color: #ed4611;
      position: relative;
      padding-left: 26px;
      &::before {
        content: "";
        background-image: url(../../../images/icons/alert.svg);
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain;
        width: 20px;
        height: 20px;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
    .regError {
      font-size: 20px;
      a {
        color: #115eed;
        font-weight: 600;
        transition: 0.3s ease;
        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
  .formBtn {
    @include formBtn();
  }
}

@media (max-width: 768px) {
  .registerForm {
    .socialBtns {
      width: 100%;
      flex-wrap: wrap;
      a {
        width: 100%;
      }
    }
  }
}

@media (max-width: 368px) {
  .registerForm {
    .formTitle {
      font-size: 24px;
    }
    .question {
      font-size: 14px;
    }
  }
}
