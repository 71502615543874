.profileCard {
  width: 100%;
  // min-width: 325px;
  height: 190px;
  background: #fff;
  border: 1px solid rgba(17, 94, 237, 0.25);
  border-radius: 4px;
  padding: 16px;
  transition: 0.3s ease;
  color: #fff;
  font-size: 12px;
  cursor: pointer;
  transition: 0.3s ease;
  &:hover {
    border-color: rgb(10, 70, 183);
  }
  .profileCardOverlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    z-index: 999;
    opacity: 0;
    visibility: hidden;
    display: none;
    transition: 0.3s ease;
    &.active {
      opacity: 1;
      visibility: visible;
      display: block;
    }
  }
  .cardTop {
    .images {
      display: flex;
      gap: 16px;
      .avatar {
        width: 56px;
        height: 56px;
        min-width: 56px;
        min-height: 56px;
        border-radius: 100%;
        position: relative;
        &.isDefault {
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            border-radius: 0;
          }
        }
        .defaultIcon {
          width: 24px;
          height: 24px;
          object-fit: contain;
        }
        &.avatarPro {
          border: 2px solid #a528ff;
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: top center;
          border-radius: 100%;
        }
        .badge {
          position: absolute;
          bottom: -2px;
          right: -2px;
          padding: 2px 6px;
          border-radius: 8px;
          background: linear-gradient(
            91.74deg,
            #a528ff 12.93%,
            #1d42ce 101.26%
          );
          color: #ffffff;
          font-size: 11px;
          font-weight: 500;
          &.defaultBadgePosition {
            padding: 2px 4px;
          }
        }
      }
      .background {
        width: 100%;
        height: 56px;
        border-radius: 8px;
        border: 2px solid #a528ff;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 6px;
        }
      }
    }
  }
  .cardBottom {
    color: #23272f;
    font-size: 16px;
    font-weight: 400;
    margin-top: 16px;
    .cardTitle {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .title {
        display: flex;
        align-items: center;
        gap: 12px;
        .fullName {
          color: #23272f;
          font-weight: 500;
        }
        .badge {
          display: flex;
          background-color: #ffa700;
          color: #fff;
          font-size: 12px;
          font-weight: 500;
          justify-content: center;
          align-items: center;
          padding: 4px 8px;
          border-radius: 12px;
        }
      }
      .companyCount {
        font-size: 12px;
        font-weight: 500;
      }
    }
    .cardInfo {
      margin-top: 12px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      .info {
        font-size: 14px;
        .phone {
          font-weight: 400;
          // margin-bottom: 12px;
          color: #23272f;
        }
        .savedCount {
          color: #23272f50;
        }
      }
      .cardBtn {
        border: 0;
        outline: 0;
        border-radius: 6px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        gap: 4px;
        cursor: pointer;
        transition: 0.3s ease;
        padding: 9px 8px;
        font-size: 12px;
        &:active {
          transform: scale(0.9);
        }
        &:hover {
          opacity: 0.8;
        }
        &.remove {
          background: #22222205;
        }
        &.false {
          background: #fff;
        }
        &.add {
          background: #115eed0f;
          color: #115eed;
          font-weight: 400;
          font-size: 14px;
        }
      }
    }
  }
  .cardOption {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    // max-width: 300px;
    // min-width: 300px;
    padding: 16px;
    z-index: 999;
    background: #fff;
    box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    position: relative;
    opacity: 0;
    visibility: hidden;
    transition: 0.3s ease;
    &.isOpen {
      opacity: 1;
      visibility: visible;
    }
    .option {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 10px;
      padding: 6px 4px;
      border-radius: 4px;
      cursor: pointer;
      transition: 0.3s ease;
      img {
        width: 20px;
        height: 20px;
        object-fit: contain;
      }
      &:hover {
        background-color: #23272f20;
      }
    }
  }
}

@media (max-width: 1024px) {
  .profileCard {
    font-size: 12px;
    padding: 8px;
    .cardTop {
      .images {
        .avatar {
          .badge {
            font-size: 11px;
            position: absolute;
            bottom: 0;
            right: 0;
            padding: 2px 8px;
            border-radius: 8px;
            background: linear-gradient(
              91.74deg,
              #a528ff 12.93%,
              #1d42ce 101.26%
            );
            color: #ffffff;
            font-size: 11px;
            font-weight: 500;
          }
        }
      }
    }
    .cardBottom {
      font-size: 16px;
      .cardTitle {
        .title {
          .badge {
            font-size: 12px;
          }
        }
        .companyCount {
          font-size: 12px;
        }
      }
      .cardInfo {
        .cardBtn {
          &.add {
            font-size: 14px;
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .profileCard {
    font-size: 12px;
    padding: 8px;
    .cardTop {
      .images {
        .avatar {
          .badge {
            font-size: 11px;
          }
        }
      }
    }
    .cardBottom {
      font-size: 16px;
      .cardTitle {
        .title {
          .badge {
            font-size: 12px;
          }
        }
        .companyCount {
          font-size: 12px;
        }
      }
      .cardInfo {
        .cardBtn {
          &.add {
            font-size: 14px;
          }
        }
      }
    }
    .cardOption {
      width: 100%;
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 16px;
      z-index: 999;
      background: #fff;
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 16px;
      box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.1);
      border-radius: 20px 20px 0 0;
      transform: translateY(100%);
      opacity: 0;
      visibility: hidden;
      transition: all 0.3s ease;
      &.isOpen {
        opacity: 1;
        visibility: visible;
        transform: translateY(0);
      }
      .seperator {
        width: 56px;
        height: 4px;
        background-color: #c3c5d4;
        margin: 0 auto 16px auto;
        border-radius: 5px;
      }
    }
  }
}

@media (max-width: 468px) {
  .profileCard {
    padding: 8px;
    .cardBottom {
      .cardInfo {
        .info {
          .profession {
            font-size: 12px;
          }
          .phone {
            font-size: 14px;
          }
        }
        .cardBtn {
          font-size: 11px;
        }
      }
    }
  }
}
