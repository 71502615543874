.profileSection {
  width: 100%;
  max-width: 619px;
  background-color: #ffffff;
  border: 1px solid #cbddeb50;
  border-radius: 8px;
  padding: 24px 30px;
  .topSide {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
    .title {
      font-size: 20px;
      font-weight: 500;
      color: #23272f;
    }
    .editIcon {
      width: 26px;
      height: 26px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #e7effd50;
      padding: 5px;
      transition: 0.3s ease;
      cursor: pointer;
      z-index: 1;
      img {
        width: 16px;
        height: 16px;
        object-fit: cover;
      }
      &:hover {
        background-color: #e7effd;
      }
    }
  }
}

@media (max-width: 425px) {
  .profileSection {
    padding: 12px 6px;
    .topSide {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 24px;
      .title {
        font-size: 16px;
        font-weight: 500;
        color: #23272f;
      }
      .editIcon {
        width: 26px;
        height: 26px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #e7effd50;
        padding: 5px;
        transition: 0.3s ease;
        cursor: pointer;
        z-index: 1;
        img {
          width: 16px;
          height: 16px;
          object-fit: cover;
        }
        &:hover {
          background-color: #e7effd;
        }
      }
    }
  }
}
