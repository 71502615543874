.profileLanguages {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  .lngCard {
    background-color: #f6f5f8;
    padding: 12px 16px;
    border-radius: 20px;
    border: 1px solid #115eed03;
    display: flex;
    gap: 10px;
    img {
      width: 20px;
      height: 20px;
      object-fit: cover;
    }
    p {
      font-size: 14px;
      font-weight: 400;
      color: #23272f;
    }
  }
}


@media (max-width:425px) {
  .profileLanguages{
    .lngCard{
      width: 100%;
    }
  }
}