@import"../../mixins";

.hero {
  width: 100%;
  background-image: url(../../../images/pattern.svg);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  padding: 110px 0 0 0;

  .container {
    .row {
      flex-direction: column;
      padding-top: 0;

      // .homeHero {
      //   display: flex;
      //   justify-content: space-between;
      //   gap: 24px;

      //   .leftSide {
      //     width: 50%;
      //     max-width: 389px;
      //     display: flex;
      //     flex-direction: column;
      //     justify-content: space-between;

      //     .homeContent {
      //       .homeTitle {
      //         font-weight: 700;
      //         font-size: 48px;
      //         line-height: 1.25;
      //         margin-bottom: 12px;
      //       }

      //       .homeInfo {
      //         color: #535861;
      //         line-height: 1.5;
      //         margin-bottom: 32px;
      //       }

      //       .btn {
      //         @include primaryBtn();
      //         width: 349px;
      //       }
      //     }

      //     .preRegister {
      //       .appInfo {
      //         font-size: 12px;
      //         line-height: 1.5;
      //         color: #535861;
      //       }

      //       .downloadApp {
      //         margin-top: 12px;
      //         display: flex;
      //         gap: 12px;

      //         .mobileApp {
      //           width: 135px;
      //           height: 40px;

      //           a {
      //             img {
      //               width: 100%;
      //               height: 100%;
      //               object-fit: contain;
      //             }
      //           }
      //         }
      //       }
      //     }
      //   }

      //   .rightSide {
      //     width: 50%;

      //     img {
      //       width: 100%;
      //       height: 100%;
      //       object-fit: contain;
      //     }
      //   }
      // }
      .homeHero {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        margin-bottom: 12px;
        .heroTitle {
          color: #23272f;
          font-size: 48px;
          font-weight: 500;
          margin-bottom: 24px;
        }
        .heroInfo {
          font-size: 18px;
          font-weight: 400;
          color: #23272f;
          max-width: 419px;
          margin-bottom: 32px;
        }
        .heroDescription {
          margin-bottom: 32px;
          display: flex;
          gap: 8px;
          .info {
            font-size: 14px;
            font-weight: 300;
          }
          .badgesContainer {
            display: flex;
            position: relative;
            margin-left: -30px;
            .badge {
              width: 20px;
              height: 20px;
              border-radius: 50%;
              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 50%;
              }
            }
            .badge:nth-child(5) {
              transform: translateX(0);
            }
            .badge:nth-child(4) {
              transform: translateX(8px);
            }
            .badge:nth-child(3) {
              transform: translateX(16px);
            }
            .badge:nth-child(2) {
              transform: translateX(24px);
            }
            .badge:nth-child(1) {
              transform: translateX(32px);
            }
          }
        }
        .btn {
          @include primaryBtn();
          margin-bottom: 120px;
          max-width: 241px;
          background: radial-gradient(79.25% 295.31% at 20.95% 0%, #115EED 0%, #27E574 100%);
          padding: 16px 14px;
          font-size: 15px;
          &:hover{
            opacity: 0.5;
          }
        }
        .mobileAppInfo {
          margin-bottom: 16px;
          font-size: 12px;
          font-weight: 400;
          max-width: 200px;
        }
        .downloadApp {
          display: flex;
          gap: 12px;
          .mobileApp {
            max-width: 163px;
            max-height: 52px;
            transition: 0.3s ease;
            a {
              width: 100%;
              height: 100%;
              img {
                width: 100%;
                height: 100%;
              }
            }
            &:hover {
              opacity: 0.5;
            }
          }
        }
      }
      .particpants {
        width: 100%;
        margin-bottom: 0;
        .rfm-marquee-container {
          .rfm-marquee {
            height: 200px;
            overflow: hidden;
            .rfm-child {
              // margin-left: 20px;
              margin: 0 20px;
            }
            .rfm-initial-child-container {
              .rfm-child {
                // margin-left: 20px;
                margin: 0 20px;
              }
            }
          }
        }

        //.marquee-container {
        //  gap: 88px;
        //  align-items: center;
        //  .marquee {
        //    gap: 88px;
        //    align-items: center;
        //    .rfm-initial-child-container {
        //      align-items: center;
        //      gap: 150px;
        //      .rfm-child {
        //        .slide {
        //          max-width: 180px;
        //          img {
        //            width: 100%;
        //            height: 100%;
        //            object-fit: contain;
        //          }
        //        }
        //      }
        //    }
        //  }
        //}
      }
    }
  }
}
// Screen width 1800px
@media (min-width: 1800px) {
  .hero {
    .container {
      .row {
        .homeHero {
          .leftSide {
            max-width: 50%;
            justify-content: center;
            align-items: center;

            .homeContent {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;

              .homeTitle {
                font-size: 80px;
              }

              .homeInfo {
                font-size: 30px;
              }

              .btn {
                width: 50%;
                font-size: 16px;
              }
            }

            .preRegister {
              margin-top: 30px;

              .appInfo {
                text-align: center;
                font-size: 20px;
              }

              .downloadApp {
                margin-top: 20px;
                justify-content: center;

                .mobileApp {
                  width: 50%;
                  height: 70px;
                }
              }
            }
          }
        }
        .particpants {
          .rfm-marquee-container {
            .rfm-marquee {
              height: 250px;
            }
          }
        }
      }
    }
  }
}

// Screen width 768px
@media (max-width: 768px) {
  .hero {
    .container {
      .row {
        .particpants {
          .rfm-marquee-container {
            .rfm-marquee {
              height: 134px;
            }
          }
        }
      }
    }
  }
}

// Screen width 425px
@media (max-width: 425px) {
  .hero {
    .container {
      .row {
        .homeHero {
          .heroTitle {
            font-size: 24px;
          }
          .heroInfo {
            font-size: 14px;
          }
          .heroDescription {
            flex-direction: column;
            .badgesContainer {
              display: flex;
            }
          }
          .btn {
            margin-bottom: 40px;
          }
        }
      }
    }
  }
}
