.upgradePackCaption {
  width: 100%;
  .caption {
    font-size: 30px;
    font-weight: 400;
    line-height: 41px;
    color: #23272f;
    max-width: 491px;
    width: 100%;
    margin-bottom: 16px;
  }
  .text {
    width: 100%;
  }
}

/* ------------------------------- Responsive ------------------------------- */
@media (max-width: 768px) {
  .upgradePackCaption {
    .caption {
      font-size: 25px;
      margin-bottom: 12px;
    }
  }
}