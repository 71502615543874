@import"../../mixins";

.notify {
  width: 100%;
  .container {
    .row {
      padding: 26px 42px;
      .notifyInfo {
        width: 100%;
        background: #115eed;
        position: relative;
        border-radius: 12px;
        padding: 48px 40px;
        position: relative;
        .title {
          font-weight: 700;
          font-size: 30px;
          line-height: 1.35;
          color: #ffffff;
          margin-bottom: 24px;
        }
        .registerBtn {
          transition: 0.3s ease;
          @include secondaryBtn();
          width: 30%;
          min-width: 400px;
          position: relative;
          z-index: 1;
          span {
            position: relative;
            margin-left: 16px;
            &::after {
              content: "/";
              position: absolute;
              top: 0;
              left: -10px;
              width: 16px;
              height: 16px;
            }
          }
          &:hover {
            color: #fff;
          }
        }
        .pattern {
          width: 100%;
          height: 100%;
          background-image: url(../../../images/pattern-triangle.png);
          background-repeat: no-repeat;
          background-size: cover;
          background-position: top right;
          position: absolute;
          top: 0;
          right: 0;
          z-index: 0;
        }
      }
    }
  }
}

// Screen width 768px
@media (max-width: 768px) {
  .notify {
    .container {
      .row {
        padding: 0;
        padding-bottom: 46px;
        .notifyInfo {
          padding: 24px 20px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          text-align: center;
          .title {
            padding-top: 180px;
            font-size: 26px;
          }
          .registerBtn {
            width: 100%;
            min-width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;
            padding: 16px 24px;
            span {
              &::after {
                display: none;
              }
            }
          }
          .pattern {
            background-image: url(../../../images/pattern-triangle-responsive.png);
            background-size: contain;
            background-position: top center;
          }
        }
      }
    }
  }
}

@media (min-width: 1800px) {
  .notify {
    .container {
      .row {
        padding: 26px 70px;
      }
    }
  }
}
