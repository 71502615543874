.upgradeBuisnessPack {
  margin: 0 -32px -32px -32px;
  padding: 0 32px 80px 32px;
  background-color: #231f20;
  .upgradePackCaption {
    margin: 20px 0;
    .caption,
    .text {
      color: #fff;
    }
    &:nth-child(1) {
      padding: 78px 0 61px;
      .text {
        max-width: 437px;
      }
    }
    &:nth-child(5) {
      padding: 156px 0 53px;
      max-width: 491px;
      width: 100%;
      margin: 0 auto;
    }
  }
  .upgradePackInfo {
    &:nth-child(4) {
      flex-direction: row-reverse;
    }
    .caption,
    .text {
      color: #fff;
    }
    .leftSide {
      .infoItem {
        .title {
          color: #fff;
        }
        &::after {
          background-image: url(../../images/icons/check-dark.svg);
        }
      }
    }
    .rightSide {
      background-color: #fff;
    }
  }
  .upgradePackNumber {
    .number {
      background: linear-gradient(90deg, #115eed 0%, #ffe4ce 100%);
      background-clip: text;
    }
    .text {
      color: #fff;
    }
  }
  .packetArea {
    width: 100%;

    .row {
      width: 100%;
      .packetCard {
        width: 100%;
      }
      .freeTrialCard {
        width: 100%;
      }
    }
  }
}

/* ------------------------------- Responsive ------------------------------- */
@media (max-width: 1024px) {
  .upgradeBuisnessPack {
    .upgradePackCaption {
      &:nth-child(1) {
        padding: 50px 0;
        .text {
          max-width: none;
          width: 100%;
        }
      }
    }
    .upgradePackInfo {
      &:nth-child(4) {
        flex-direction: column-reverse;
      }
    }
  }
}

@media (max-width: 768px) {
  .upgradeBuisnessPack {
    margin: 0 -16px;
    padding: 0 16px 80px 16px;
  }
}
