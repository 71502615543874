.selectLang {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border: 1px solid #23272f1f;
  padding: 5px;
  border-radius: 8px;
  cursor: pointer;
  transition: 0.5s all;
  width: 32px;
  height: 32px;
  &:hover {
    border-color: #115eed;
  }
  .overlay {
    display: none;
    width: 100%;
    height: 100vh;
    opacity: 0.7;
    background: transparent;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    &.active {
      display: block;
    }
  }
  .langBox {
    position: absolute;
    top: 40px;
    right: -20px;
    padding: 24px;
    border-radius: 6px;
    background: #fff;
    border: 1px solid rgba(35, 39, 47, 0.1);
    display: flex;
    flex-direction: column;
    gap: 32px;
    transition: 0.15s cubic-bezier(0.4, 0, 0.2, 1);
    opacity: 0;
    visibility: hidden;
    transform: scale(0);
    transform-origin: top right;
    z-index: 1000;
    .seperator {
      display: none;
      width: 56px;
      height: 4px;
      border-radius: 7px;
      background: #c3c5d4;
      margin: 0 auto;
    }
    &.active {
      opacity: 1;
      visibility: visible;
      transform: scale(1);
    }
    .lang {
      cursor: pointer;
      display: flex;
      gap: 16px;
      transition: 0.15s cubic-bezier(0.4, 0, 0.2, 1);
      &:hover {
        transform: scale(1.1);
      }
      &:active {
        transform: scale(0.9);
      }
    }
  }
}

@media (max-width: 768px) {
  .selectLang {
    .overlay {
      display: none;
    }
    .langBox {
      display: none;
    }
  }
}
