@import "../mixins";

.freeTrialCard {
  width: 100%;
  background: #ffffff;
  background: linear-gradient(91.74deg, #a528ff 12.93%, #1d42ce 101.26%);
  border: 1px solid #115eed1f;
  border-radius: 0px 0px 4px 4px;
  border-radius: 8px;
  font-weight: 500;
  padding: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  transition: 0.3s ease;
  .cardTitle {
    font-size: 18px;
    color: #fff;
    font-weight: 400;
    margin-bottom: 12px;
  }
  .cardLimit {
    font-size: 32px;
    color: #fff;
    margin-bottom: 16px;
  }
  .division {
    margin: 24px 0;
    display: inline-block;
    width: 100%;
    height: 1px;
    background-color: #e1e7f2;
  }
  .desc {
    font-weight: 500;
    color: #fff;
    position: relative;
    margin-bottom: 32px;
  }
  .cardBtn {
    @include primaryBtn();
    font-weight: 500;
    font-size: 16px;
  }
}

@media (max-width: 368px) {
  .freeTrialCard {
    font-size: 12px;
  }
}
