.companyPage {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 30px;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #fff;
  z-index: 1000;
  h2 {
    font-size: 50px;
  }
  p {
    color: #1155ed;
    cursor: pointer;
    transition: 0.3s ease;
    &:hover {
      opacity: 0.8;
    }
  }
}
