@import "../mixins";

.sideBar {
  position: fixed;
  top: 78px;
  left: 0;
  width: 21%;
  height: calc(100vh - 78px);
  display: flex;
  z-index: 999;
  &.profilePage {
    width: 5%;
  }
  .leftSide {
    padding: 24px 16px;
    max-width: 72px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    border-right: 1px solid #cbddeb;
    background: #fff;
    .leftTop {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 32px;
      .badge {
        position: relative;
        cursor: pointer;
        transition: 0.3s ease;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          border-radius: 50%;
          &.profileImg {
            object-fit: cover;
          }
        }
        &:hover {
          transform: scale(1.05);
        }
        &::before {
          display: none;
          position: absolute;
          top: 0;
          left: calc(0px - 20px);
          height: 100%;
          width: 8px;
          content: "";
          background: #115eed;
          border-radius: 10px;
        }
        &.active {
          &::before {
            display: inline-block;
          }
        }
      }
      .divider {
        display: inline-block;
        width: 100%;
        height: 1px;
        background: #cbddeb;
        border-radius: 2px;
      }
    }
    .leftCenter {
      background-color: #000;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .leftBottom {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 32px;
      .badge {
        cursor: pointer;
        transition: 0.3s ease;
        &:hover {
          transform: scale(1.1);
        }
      }
      .divider {
        display: inline-block;
        width: 100%;
        height: 1px;
        background: #cbddeb;
        border-radius: 2px;
      }
    }
  }
  .rightSide {
    padding: 32px 12px;
    border-right: 1px solid #cbddeb;
    max-width: 232px;
    min-width: 232px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background: #fff;

    .menuList {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 4px;

      .menuListItem {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 12px;
        padding: 12px 16px;
        color: #000;
        background: #fff;
        transition: 0.3s ease;
        border-radius: 8px;
        cursor: pointer;
        .soon {
          opacity: 0.5;
        }
        .itemTitle {
          font-size: 14px;
          font-weight: 400;
          line-height: 14px;
          color: #292d32;

          transition: 0.3s ease;
        }
        img {
          width: 20px;
          height: 20px;
        }
        &.active {
          background: #f1f5f7;
        }
        &:hover {
          background: #f1f5f7;
        }
      }
    }
    .upgradeBox {
      min-width: 184px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 16px;
      .orderBucard {
        width: 100%;
        display: none;
        justify-content: center;
        align-items: center;
        gap: 14px;
        border-radius: 8px;
        background: linear-gradient(92deg, #4183fd 12.93%, #1d42ce 101.26%);
        padding: 16px;
        outline: 0;
        border: 0;
        cursor: pointer;
        transition: 0.3s ease;

        &:hover {
          transform: scale(1.05);
        }
        .orderInfo {
          color: #fff;
          font-size: 14px;
          font-weight: 500;
          white-space: nowrap;
        }
        .cardImg {
          width: 43px;
          height: 28px;
          border-radius: 4px;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 4px;
          }
        }
      }
      .upgradeBtn {
        @include upgradeBtn();
      }
    }
  }
  .mobileSide {
    width: 100%;
    padding: 0 16px;
    display: none;
    position: relative;
    .mobileHead {
      width: 100%;
      height: 80px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .logo {
        width: 100px;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
      .btns {
        display: flex;
        align-items: center;
        gap: 12px;
        .close {
          width: 24px;
          height: 24px;
          background: #f6f5f8;
          border-radius: 50%;
          cursor: pointer;
          transition: 0.3s ease;
          &:hover {
            opacity: 0.8;
          }
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
      }
    }
    .mobileBody {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      gap: 10px;
      // .accountMenu {
      //   display: flex;
      //   opacity: 1;
      //   visibility: visible;
      //   flex-direction: column;
      //   align-items: flex-start;
      //   justify-content: center;
      //   gap: 10px;
      //   min-width: 300px;

      //   border-radius: 12px;
      //   transition: display 0.3s ease;
      //   transition: opacity 0.3s ease;
      //   z-index: 1000;
      //   .accountMenuItem {
      //     width: 100%;
      //     box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.1);
      //     border-radius: 15px;
      //     a {
      //       width: 100%;
      //       display: flex;
      //       justify-content: flex-start;
      //       align-items: center;
      //       gap: 10px;
      //       padding: 16px;
      //       color: #000;
      //       background: #fff;
      //       transition: 0.3s ease;
      //       border-radius: 8px;
      //       img {
      //         width: 28px;
      //         height: 28px;
      //         border-radius: 50%;
      //         object-fit: cover;
      //       }
      //       &:hover {
      //         background: #115eed;
      //         color: #fff;
      //       }
      //     }
      //     &.goPro {
      //       a {
      //         background: #115eed;
      //         color: #fff;
      //         &:hover {
      //           img {
      //             filter: invert(0%);
      //           }
      //         }
      //       }
      //     }
      //   }
      // }
      .menu {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 12px;
        .accountMenuItem {
          width: 100%;
          box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.1);
          border-radius: 15px;
          a {
            width: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 10px;
            padding: 16px;
            color: #000;
            background: #fff;
            transition: 0.3s ease;
            border-radius: 8px;
            img {
              width: 28px;
              height: 28px;
              border-radius: 50%;
              object-fit: cover;
            }
            &:hover {
              background: #115eed;
              color: #fff;
            }
          }
          &.goPro {
            a {
              background: #115eed;
              color: #fff;
              &:hover {
                img {
                  filter: invert(0%);
                }
              }
            }
          }
        }
        .menuItem {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          gap: 12px;
          padding: 8px 16px;
          background: #fff;
          border-radius: 15px;
          cursor: pointer;
          transition: 0.3s ease;
          box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.1);

          &:hover {
            opacity: 0.8;
          }
          .itemName {
            color: #000;
          }
          .icon {
            img {
              filter: invert(100%);
            }
          }
        }
      }
      .upgradeBox {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 16px;
        .orderBucard {
          width: 100%;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 14px;
          border-radius: 8px;
          background-color: #fff;
          padding: 16px;
          outline: 0;
          border: 0;
          box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.1);
          border-radius: 15px;
          cursor: pointer;
          transition: 0.3s ease;
          &:hover {
            transform: scale(1.05);
          }
          .orderInfo {
            color: #000;
            font-size: 14px;
            font-weight: 500;
            white-space: nowrap;
          }
          .cardImg {
            width: 28px;
            height: 28px;
            border-radius: 4px;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              border-radius: 4px;
            }
          }
        }
        .upgradeBtn {
          border: 0;
          outline: 0;
          width: 100%;
          padding: 16px;
          cursor: pointer;
          border-radius: 8px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap:12px;
          background-color: #fff;
          box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.1);
          border-radius: 15px;
          transition: all 0.3s ease linear;
          p {
            color: #000;
            font-weight: 500;
          }
          .upgradeIcon {
            width: 28px;
            height: 28px;
            padding: 7px 15px;
            border-radius: 50%;
            background: linear-gradient(
              91.74deg,
              #a528ff 12.93%,
              #1d42ce 101.26%
            );
            color: #ffffff;
            font-size: 11px;
            font-weight: 500;
          }
        }
      }
    }
    .logOut {
      position: fixed;
      bottom: 100px;
      left: calc(50% - 25px);
      p {
        color: #115eed;
        font-size: 14px;
        cursor: pointer;
        transition: 0.3s ease;
        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .sideBar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: block;
    background: #fff;
    transform: translateX(-100%);
    transition: all 0.3s ease;
    opacity: 0;
    visibility: hidden;
    &.isOpen {
      transform: translateX(0);
      opacity: 1;
      visibility: visible;
    }
    .leftSide {
      display: none;
    }
    .rightSide {
      display: none;
    }
    .mobileSide {
      display: block;
    }
  }
}
