.profileKeywordsEdit {
  display: flex;
  flex-direction: column;
  gap: 20px;
  .css-13cymwt-control {
    padding: 10px;
  }
  .infoKeywordsForm {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .keywordInput {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: center;
      margin: 10px 0;
      .customInput {
        width: 100%;
      }
    }
  }
  .btnGroup {
    display: flex;
    align-items: center;
    gap: 12px;
    justify-content: flex-end;
    .editBtn {
      display: flex;
      width: 65%;
      gap: 10px;
    }
    .btn {
      width: 100%;
      border: 0;
      outline: 0;
      border: 1px solid transparent;
      padding: 12px;
      border-radius: 4px;
      font-size: 14px;
      font-weight: 400;
      background-color: #115eed;
      color: #ffffff;
      cursor: pointer;
      transition: 0.3s ease;
      &:hover {
        border-color: #115eed50;
      }
      &.cancel {
        background-color: #115eed03;
        color: #000000;
      }
    }
  }
  span {
    color: #535861;
    font-size: 14px;
    font-weight: 400;
  }
  .editSkillsArea {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    .skillsCaption {
      background-color: #f6f5f8;
      border-radius: 20px;
      outline: none;
      border: none;
      display: flex;
      gap: 10px;
      padding: 9px 16px;
      color: #23272f;
      font-size: 14px;
      font-weight: 400;
      span {
        color: #535861;
        cursor: pointer;
        transition: 0.3s ease;
        font-size: 14px;
        &:hover {
          color: #115eed;
        }
      }
    }
  }
  .addSkillsArea {
    display: flex;
    flex-direction: column;
    gap: 10px;
    .row {
      width: 100%;
      padding: 0;
      align-items: center;
      gap: 16px;
      .customInput {
        width: 90%;
        input {
          padding: 18px 14px;
        }
      }
      .addBtn {
        margin-top: 32px;
        button {
          display: flex;
          justify-content: center;
          background-color: #e7effd;
          border: none;
          outline: none;
          gap: 6px;
          border-radius: 17px;
          padding: 8px 12px;
          font-size: 12px;
          font-weight: 400;
          color: #115eed;
          cursor: pointer;
          transition: 0.5s all ease-in-out;
          &:disabled {
            opacity: 0.5;
          }
        }
      }
    }
    .infoSkills {
      display: flex;
      gap: 10px;
      font-size: 14px;
      color: #979797;
    }
  }
}

@media (max-width: 768px) {
  .profileSkillsEdit {
    .infoSkillsEdit {
      .btnGroup {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .editBtn {
          width: 100%;
        }
      }
      .addBtn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        button {
          width: 100%;
        }
      }
      .editSkillsArea {
        flex-wrap: wrap;
        justify-content: center;
      }
    }
  }
}
