.companies {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 30px;
  .sectionTitle {
    font-weight: 500;
    font-size: 28px;
    color: #000000;
  }
  .alphabetBox {
    width: 100%;
    background: rgba(242, 247, 255, 0.25);
    border-radius: 6px;
    margin-left: 16px;
    .alphabets {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: wrap;
      gap: 4px;
      .title {
        font-size: 14px;
        color: #23272f;
        padding: 12px 24px;
        background: #ffffff;
        box-shadow: 0px 0px 12px rgba(35, 31, 32, 0.06);
        border-radius: 6px;
        cursor: pointer;
        transition: 0.3s ease;
        &:hover {
          background: #1155ed;
          color: #fff;
        }
        &.active {
          background: #1155ed;
          color: #fff;
        }
      }
      .alphabet {
        width: 2.3%;
        font-size: 16px;
        color: #23272f;
        padding: 10px 0;
        text-align: center;
        cursor: pointer;
        transition: 0.3s ease;
        border-radius: 6px;
        &.active {
          background: #1155ed;
          box-shadow: 0px 0px 12px rgba(35, 31, 32, 0.06);
          color: #fff;
        }
        &:hover {
          background: #1155ed;
          box-shadow: 0px 0px 12px rgba(35, 31, 32, 0.06);
          color: #fff;
        }
      }
    }
  }
  .cardBox {
    display: flex;
    flex-wrap: wrap;
    gap: 90px;
    .companyCard {
      width: 220px;
      height: 246px;
      .cardTitle {
        font-weight: 500;
        font-size: 28px;
        color: #000000;
        margin-bottom: 12px;
      }
      .companyName {
        margin-bottom: 16px;
        a {
          font-size: 14px;
          color: #23272f;
          transition: 0.3s ease;
          cursor: pointer;
          &:hover {
            color: #1155ed;
          }
        }
      }
    }
  }
}
