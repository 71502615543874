.profileSkills {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  .skillCard {
    background-color: #f6f5f8;
    padding: 12px 16px;
    border-radius: 20px;
    border: 1px solid #115eed03;
    p {
      font-size: 14px;
      font-weight: 400;
      color: #23272f;
    }
  }
}
