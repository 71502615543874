.mobileBox {
  width: 100%;
  border: 1px solid #cbddeb;
  border-radius: 8px;
  padding: 16px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: 0.3s ease;
  cursor: pointer;
  position: relative;
  background: #fff;
  &:nth-child(3) {
    border: 1px solid #12db63;
  }
  &:nth-child(4) {
    border: 1px solid #ffa700;
  }
  &.active {
    border: 1px solid #115eed;
  }
  &:hover {
    border: 1px solid #115eed;
  }
  .boxLeft {
    display: flex;
    align-items: center;
    gap: 12px;
    .boxIcon {
      width: 56px;
      height: 56px;
      border-radius: 50%;
      background: #f6f5f8;
      display: flex;
      justify-content: center;
      align-items: center;
      .emoji {
        font-size: 24px;
      }
    }
    .boxInfo {
      display: flex;
      flex-direction: column;
      gap: 4px;
      .boxName {
        font-weight: 500;
        color: #23272f;
      }
      .boxCount {
        display: flex;
        gap: 6px;
        .userCount {
          font-weight: 500;
          color: #000;
        }
      }
    }
  }
  .boxRight {
    margin-right: -10px;
    .boxMenu {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 6px 5px;
      img {
        padding: 6px 5px;
      }
    }
  }
  .boxOptions {
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 16px;
    z-index: 999;
    background: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 16px;
    box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.1);
    border-radius: 20px 20px 0 0;
    transform: translateY(100%);
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease;
    &.isOpen {
      opacity: 1;
      visibility: visible;
      transform: translateY(0);
    }
    .seperator {
      width: 56px;
      height: 4px;
      background-color: #c3c5d4;
      margin: 0 auto 16px auto;
      border-radius: 5px;
    }
    .boxDetail {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 16px;
      .boxIcon {
        width: 20px;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        .emoji {
          font-size: 24px;
        }
      }
      p {
        color: #000;
        font-size: 20px;
        font-weight: 500;
      }
    }
    .option {
      width: 100%;
      padding: 10px 12px;
      border: 1px solid #1155ed;
      border-radius: 8px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 10px;
      transition: 0.3s ease;
      &:hover {
        background: #115eed;
        border-color: transparent ;
        &:last-child {
          background-color: red;
        }
        .icon {
          svg {
            path {
              stroke: #fff;
            }
          }
        }
        .optionInfo {
          color: #fff;
        }
      }
      .icon {
        display: flex;
        width: 24px;
        height: 24px;
      }
      .optionInfo {
        font-weight: 500;
        color: #000000;
      }
    }
  }
}
