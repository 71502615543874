@import "../mixins";

.packetCard {
  background: #ffffff;
  border: 1px solid #115eed1f;
  border-radius: 0px 0px 4px 4px;
  border-radius: 8px;
  font-weight: 500;
  padding: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  transition: 0.3s ease;
  .cardTitle {
    font-size: 18px;
    color: #115eed;
    font-weight: 400;
    margin-bottom: 12px;
  }
  .cardPrice {
    font-size: 32px;
    color: #23272f;
    margin-bottom: 16px;
    span {
      margin-left: 4px;
      font-weight: 400;
      font-size: 14px;
      color: #535861;
    }
  }
  .freePrice {
    font-weight: 400;
    font-size: 14px;
    color: #535861;
  }
  .packetDiscount {
    font-weight: 400;
    font-size: 14px;
    color: #535861;
    display: flex;
    align-items: center;
    .annually {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 8px;
      padding: 12px;
      height: 20px;
      background: #12db63;
      border-radius: 12px;
      color: #fff;
      font-size: 16px;
    }
  }
  .division {
    margin: 24px 0;
    display: inline-block;
    width: 100%;
    height: 1px;
    background-color: #e1e7f2;
  }
  .featureList {
    margin-bottom: 8px;
    .listItem {
      font-weight: 500;
      color: #535861;
      position: relative;
      padding-left: 32px;
      margin-bottom: 12px;
      &::after {
        content: "";
        width: 14px;
        height: 14px;
        position: absolute;
        top: 5px;
        left: 0px;
        background-image: url(../../images/icons/check-solid.svg);
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain;
      }
    }
    .more {
      font-size: 14px;
      text-align: left;
      color: #115eed !important;
      text-decoration: underline;
      cursor: pointer;
    }
    .whiteText {
      color: #ffffff !important;
    }
  }
  .moreDetails {
    font-size: 14px;
    text-decoration-line: underline;
    color: #115eed;
    margin-bottom: 32px;
  }
  .cardBtn {
    @include primaryBtn();
    font-weight: 500;
    font-size: 16px;
  }
  &:nth-child(1) {
    background: #115eed;
    border: 2px solid #115eed;
    .cardTitle {
      color: #fff;
      font-size: 32px;
      font-weight: 600;
      line-height: 32px;
      letter-spacing: 0.015em;
    }
    .cardPrice {
      color: #fff;
      span {
        color: #fff;
      }
    }
    .freePrice {
      color: #fff;
    }
    .packetDiscount {
      color: #fff;
      .annually {
        background: #12db63;
        color: #fff;
      }
    }
    .division {
      background-color: #e1e7f2;
    }
    .featureList {
      .listItem {
        color: #fff;
        &::after {
          background-image: url(../../images/icons/check-solid-white.svg);
        }
      }
      .more {
        font-family: Poppins;
        font-size: 14px;
        font-weight: 400;
        line-height: 14px;
        letter-spacing: 0em;
        text-align: left;
        color: #ffffff;
        text-decoration: underline;
        cursor: pointer;
      }
    }
    .moreDetails {
      color: #fff;
    }
    .cardBtn {
      background-color: #ffffff;
      color: #115eed;
      transition: 0.3s ease;
      border: 1px solid #115eed;
      &:hover {
        background-color: #115eed;
        color: #ffffff;
        border-color: #ffffff;
      }
    }
  }
}

@media (max-width: 1024px) {
  .packetCard {
    &:nth-child(1) {
      .cardTitle {
        font-size: 25px;
      }
    }
  }
}
@media (max-width: 768px) {
  .packetCard {
    &:nth-child(1) {
      .cardTitle {
        font-size: 18px;
      }
    }
  }
}
@media (max-width: 368px) {
  .packetCard {
    font-size: 12px;
    .cardBtn {
      width: 100%;
      padding: 16px 10px;
    }
  }
  .freeTrialCard {
    font-size: 12px;
    .cardBtn {
      width: 100%;
      padding: 16px 10px;
    }
  }
}
