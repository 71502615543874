.editExperience {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
  .experienceTitle {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    .experienceInfo {
      color: #23272f;
      font-size: 20px;
      font-weight: 500;
    }
    .editExperienceBtn {
      border: none;
      background-color: transparent;
      cursor: pointer;
    }
  }
  .profileExperience {
    .expCard {
      .info {
        width: 100%;
        .editPosition {
          display: flex;
          justify-content: space-between;
          button {
            border: none;
            background-color: transparent;
            cursor: pointer;
            color: #115eed;
            font-size: 14px;
            font-weight: 400;
            &.removeBtn {
              color: red;
            }
          }
        }
      }
    }
  }
  .btnGroup {
    display: flex;
    align-items: center;
    gap: 12px;
    justify-content: flex-end;
    .addBtn {
      width: 100%;
      button {
        display: flex;
        justify-content: center;
        background-color: #e7effd;
        border: none;
        outline: none;
        gap: 6px;
        border-radius: 17px;
        padding: 8px 12px;
        font-size: 12px;
        font-weight: 400;
        color: #115eed;
        cursor: pointer;
        transition: 0.5s all ease-in-out;
      }
    }
    .btn {
      width: 100%;
      border: 0;
      outline: 0;
      border: 1px solid transparent;
      padding: 12px;
      border-radius: 4px;
      font-size: 14px;
      font-weight: 400;
      background-color: #115eed;
      color: #ffffff;
      cursor: pointer;
      transition: 0.3s ease;
      &:hover {
        border-color: #115eed50;
      }
      &.cancel {
        background-color: #115eed03;
        color: #000000;
      }
    }
  }
}

@media (max-width: 450px) {
  .editExperience {
    .btnGroup {
      flex-direction: column;
      .addBtn {
        width: 100%;
        button {
          width: 100%;
        }
      }
    }
  }
}
