.securityTab {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 40px;
    border: 1px solid #efefef;
    border-radius: 4px;
    background-color: #fff;
    padding: 40px 58px;
    .securityTabHeader {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 14px;
      h2 {
        color: #23272f;
        font-size: 18px;
        font-weight: 500;
      }
      p {
        color: #535861a3;
        font-size: 14px;
        font-weight: 400;
      }
    }
    .securityTabList {
      width: 100%;
      border: 1px solid #d9d9d938;
      border-radius: 6px;
      padding: 8px 16px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 4px;
      .securityTabListItem {
        width: 100%;
        padding: 21px 4px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #fff;
        color: #23272f;
        border-radius: 6px;
        cursor: pointer;
        transition: all 0.3s ease;
        .icon {
          width: 20px;
          height: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
        &.active,
        &:hover {
          background-color: #0e4cbe;
          color: #fff;
        }
      }
    }
  }
  
  @media (max-width: 468px) {
    .securityTab {
      padding: 16px;
      gap: 16px;
      .securityTabHeader {
        gap: 8px;
      }
      .securityTabList {
        padding: 0;
        overflow-y: auto;
        .securityTabListItem {
          padding: 12px 4px;
        }
      }
    }
  }
  