.profileExperience {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  .expCard {
    .top {
      display: flex;
      align-items: center;
      gap: 12px;
      margin-bottom: 12px;
      .info {
        .title {
          font-size: 16px;
          font-weight: 500;
          color: #23272f;
        }
        .subTitle {
          span {
            font-size: 12px;
            font-weight: 400;
            color: #4a4f5d;
          }
          .dot {
            display: inline-block;
            padding: 0 4px;
          }
        }
      }
      .expImg {
        width: 56px;
        height: 56px;
        border-radius: 8px;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 8px;
        }
      }
    }
    .bottom {
      p {
        font-size: 12px;
        font-weight: 400;
        color: #23272f;
        margin-bottom: 12px;
      }
      .dates {
        span {
          font-weight: 600;
          color: #115eed85;
          margin-left: 8px;
        }
      }
    }
  }
}
