.generalTab {
  width: 100%;
  .generalTabHeader {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 12px;
    margin-bottom: 36px;
    h2 {
      font-weight: 500;
      color: #000;
    }
    p {
      font-size: 14px;
      font-weight: 400;
      color: #535861a3;
    }
  }
  .imagesHeader {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    h3 {
      font-weight: 500;
      color: #000;
    }
    label {
      font-size: 14px;
      font-weight: 400;
      color: #115eed;
      transition: 0.3s ease;
      cursor: pointer;
      &:hover {
        opacity: 0.8;
      }
    }
  }
  .profileImage {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 20px;
    .imagesBody {
      padding: 20px 0;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      div {
        width: 120px;
        height: 120px;
        border-radius: 6px;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center center;
          border-radius: 6px;
        }
      }
    }
  }
  .backgroundImage {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .imagesBody {
      padding: 20px 0;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      div {
        width: 100%;
        height: 300px;
        border-radius: 6px;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center center;
          border-radius: 6px;
        }
      }
    }
  }
  .saveButton {
    margin-top: 33px;
  }
}

@media (max-width: 468px) {
  .generalTab {
    .generalTabHeader {
      margin-bottom: 16px;
    }
    .imagesHeader {
      margin-bottom: 0;
    }
    .profileImage {
      margin-bottom: 0;
      .imagesBody {
        padding: 20px 0;
      }
    }
    .backgroundImage {
      .imagesBody {
        padding: 20px 0;
        div {
          height: 150px;
        }
      }
    }
    .saveButton {
      margin-top: 0;
    }
  }
}
