@import"../../mixins";

.orderBucard {
  padding: 100px 20px 100px 370px;
  .dashboardTitle {
    .mobileSettings {
      display: none;
      transition: 0.3s ease;
      cursor: pointer;
      width: 56px;
      &:hover {
        opacity: 0.8;
      }
    }
    .sectionTitle {
      font-weight: 500;
      font-size: 28px;
      color: #000000;
    }
  }
  .cardList {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    .card {
      width: 23%;
      transition: 0.3s ease;
      cursor: pointer;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        transition: all 0.3s ease;
      }
      .btn {
        @include primaryBtn();
        width: 100%;
        margin: 0 auto;
        opacity: 0;
        visibility: hidden;
        &.active {
          opacity: 1;
          visibility: visible;
        }
      }
    }
    .selectedCard {
      z-index: 1001;
      transition: 0.3s ease;
    position: absolute;
    top: 31%;
    left: 40%;
    }
  }
  
  
  .overlay {
    width: 100%;
    height: 100vh;
    background-color: #00000080;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    opacity: 0;
    visibility: hidden;
    &.active {
      opacity: 1;
      visibility: visible;
    }
  }
  .btn {
    @include primaryBtn();
    z-index: 1001;
  }
}
@media (max-width:1024px) {
  .orderBucard{
  .cardList{
    .selectedCard{
      z-index: 1001;
      transition: 0.3s ease;
      position: absolute;
      top: 35%;
      left: 44%;
      width: 31%;
    }

  }
  }
}
@media (max-width: 768px) {
  .orderBucard {
    padding: 30px 20px;
    .dashboardTitle {
      .mobileSettings {
        display: block;
      }
      .sectionTitle {
        text-align: center;
      }
    }
    .cardList {
      justify-content: center;
      .card {
        width: 50%;
      }
      .selectedCard {
        transform: translateY(-10%);
        margin-left: -16%;
        width: 53%;
      }
    }
  }
}

@media (max-width: 425px) {
  .orderBucard {
    .cardList {
      height: auto;
      .card {
        width: 100%;
      }
      .selectedCard {
        transform: translateY(-10%);
        margin-left: -36%;
        width: 85%;
      }
    }
  }
}
