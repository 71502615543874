.dashboardBoxes {
  .dashboardTitle {
    .mobileSettings {
      display: none;
      transition: 0.3s ease;
      cursor: pointer;
      width: 56px;
      &:hover {
        opacity: 0.8;
      }
    }
    .sectionTitle {
      font-weight: 400;
      font-size: 28px;
      color: #000000;
      margin-bottom: 24px;
    }
    .addIcon {
      display: none;
      justify-content: center;
      align-items: center;
      padding: 6px 16px;
      background: #f6f5f8;
      border-radius: 64px;
    }
  }
  .boxes {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 1%;
    row-gap: 16px;
    position: relative;
    .box {
      width: calc(25% - 12px);
    }
    .outsideClickOverlay {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      background-color: transparent;
      display: none;
      &.isOpen {
        display: block;
      }
    }
  }
}

@media (max-width: 1024px) {
  .dashboardBoxes {
    .boxes {
      .box {
        width: calc(33% - 12px);
      }
    }
  }
}

@media (max-width: 998px) {
  .dashboardBoxes {
    .boxes {
      .box {
        width: calc(50% - 12px);
      }
    }
  }
}


@media (max-width: 768px) {
  .dashboardBoxes {
    // margin-top:56px;
    .dashboardTitle {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 12px;
      .mobileSettings {
        display: block;
      }
      .sectionTitle {
        font-size: 18px;
        margin-bottom: 0;
      }
      .addIcon {
        display: flex;
      }
    }
    .boxes {
      .box {
        &.addBox {
          display: none;
        }
      }
      .outsideClickOverlay {
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 999;
      }
    }
  }
}
