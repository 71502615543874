.boxDetails {
  width: 100%;
  padding: 96px 16px;
  position: relative;
  .boxOption {
    display: flex;
    align-items: center;
    justify-content: center;
    .addBtn {
      border: 0;
      outline: 0;
      padding: 12px 24px;
      background: #115eed;
      border-radius: 4px;
      font-weight: 500;
      color: #ffffff;
      cursor: pointer;
      transition: 0.3s ease;
      &:hover {
        opacity: 0.8;
      }
    }
  }
  .profiles {
    width: 100%;
    .topBar {
      width: 100%;
      display: flex;
      justify-content: space-between;
      .boxName {
        display: flex;
        align-items: center;
        gap: 16px;
        font-weight: 500;
        font-size: 20px;
        color: #000000;
        .title {
          font-size: 24px;
          color: #000000;
        }
        .goBack {
          display: flex;
          align-items: center;
          gap: 8px;
          padding: 4px 12px 4px 4px;
          background: #f6f5f8;
          border-radius: 16px;
          cursor: pointer;
          transition: 0.3s ease;
          &:hover {
            background: #ddd;
          }
          span {
            color: #23272f50;
            font-size: 12px;
          }
        }
      }
      .boxOption {
        display: flex;
        align-items: center;
        gap: 24px;
        .resultCount {
          color: #535861;
        }
        .addBtn {
          border: 0;
          outline: 0;
          padding: 12px 24px;
          background: #115eed;
          border-radius: 4px;
          font-weight: 500;
          color: #ffffff;
          cursor: pointer;
          transition: 0.3s ease;
          &:hover {
            opacity: 0.8;
          }
        }
      }
    }
    .profilesInfo {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      .users {
        width: 100%;
        margin-top: 16px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        gap: 16px;
        .profileCard {
          width: calc(25% - 12px);
        }
      }
      .emptyBox {
        width: 100%;
        padding-top: 120px;
        text-align: center;
        .info {
          font-size: 24px;
          color: #23272f;
          font-size: 700;
          span {
            color: #1155ed;
            font-weight: 900;
          }
        }
      }
    }
  }
  .boxOptionRes {
    width: 100%;
    display: none;
    align-items: center;
    gap: 24px;
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 16px 20px;
    box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    .resultCount {
      color: #535861;
    }
    .addBtn {
      width: 100%;
      border: 0;
      outline: 0;
      padding: 12px 24px;
      background: #3e3ef4;
      border-radius: 120px;
      font-weight: 500;
      color: #ffffff;
      cursor: pointer;
      transition: 0.3s ease;
      &:hover {
        opacity: 0.8;
      }
    }
  }
}

@media (max-width: 1024px) {
  .boxDetails {
    .profiles {
      .topBar {
        flex-direction: column;
        gap: 8px;
        .boxName {
          .title {
            font-size: 18px;
          }
        }
        .boxOption {
          display: none;
          .addBtn {
            width: 100%;
          }
        }
      }
      .profilesInfo {
        .users {
          padding-bottom: 50px;
          .profileCard {
            width: calc(33% - 12px);
          }
        }
        .emptyBox {
          .info {
            font-size: 16px;
          }
        }
      }
    }
    .boxOptionRes {
      display: flex;
    }
  }
}

@media (max-width: 768px) {
  .boxDetails {
    padding: 30px 16px;
    .profiles {
      .profilesInfo {
        .users {
          padding-bottom: 50px;
          .profileCard {
            width: calc(50% - 12px);
          }
        }
      }
    }
  }
}

@media (max-width: 560px) {
  .boxDetails {
    .profiles {
      .profilesInfo {
        .users {
          padding-bottom: 50px;
          .profileCard {
            width: 100%;
          }
        }
      }
    }
  }
}