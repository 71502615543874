.changePasswordTab {
  width: 100%;
  .changePasswordForm {
    width: 100%;
    .formHead {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .titles {
        display: flex;
        flex-direction: column;
        gap: 14px;
        h4 {
          font-size: 18px;
          font-weight: 500;
          color: #000000;
        }
        p {
          font-size: 14px;
          color: #535861a3;
        }
      }
    }
    .formBody {
      display: flex;
      flex-direction: column;
      gap: 40px;
      margin: 40px 0;
      .saveButton {
        &.mobile {
          display: none;
        }
      }
      .customInput {
        label {
          font-size: 14px;
          color: #535861;
        }
        input {
          font-size: 14px !important;
        }
        &::placeholder {
          font-size: 14px;
          background: #979797;
        }
      }
    }
  }
  a {
    font-size: 14px;
    color: #535861;
    transition: 0.3s ease;
    &:hover {
      color: #115eed;
    }
  }
}

@media (max-width: 768px) {
  .changePasswordTab {
    width: 100%;
    .changePasswordForm {
      width: 100%;
      .formBody {
        margin: 24px 0;
      }
    }
    .saveButton {
      justify-content: flex-start;
      margin: 24px 0;
      .btn {
        width: 100%;
      }
    }
  }
}
