.profileStatusTab {
  width: 100%;
  h3 {
    font-size: 18px;
    font-weight: 500;
    color: #000000;
  }
  .tabHead {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 51px 0 24px 0;
    h4 {
      font-size: 16px;
      font-weight: 500;
      color: #000000;
    }
  }
  .desc {
    font-size: 14px;
    color: #535861;
  }
}
