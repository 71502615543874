.profileInfoEdit {
  width: 100%;
  .infoEditForm {
    display: flex;
    flex-direction: column;
    gap: 23px;
    .btnAdd{
      display: flex;
      justify-content: center;
      width: 20%;
      background-color: #E7EFFD;
      border: none;
      outline: none;
      gap: 6px;
      border-radius: 17px;
      padding: 8px 12px;
      font-size: 12px;
      font-weight: 400;
      color: #115EED;
      cursor: pointer;
      transition: 0.5s all ease-in-out;
      &:hover {
        background-color: #115eed50;
      }
    }
  }

  .locationBtn {
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    transition: 0.3s ease;
    &:hover {
      p {
        color: #115eed;
      }
    }
    p {
      font-size: 16px;
      font-weight: 400;
      color: #292d32;
      transition: 0.3s ease;
    }
  }
  .btnGroup {
    display: flex;
    align-items: center;
    gap: 12px;
    justify-content: flex-end;
    .btn {
      width: 164px;
      border: 0;
      outline: 0;
      border: 1px solid transparent;
      padding: 12px;
      border-radius: 4px;
      font-size: 14px;
      font-weight: 400;
      background-color: #115eed;
      color: #ffffff;
      cursor: pointer;
      transition: 0.3s ease;
      &:hover {
        border-color: #115eed50;
      }
      &.cancel {
        background-color: #115eed03;
        color: #000000;
      }
    }
  }
}


@media (max-width: 425px) {
  .profileInfoEdit {
    .infoEditForm{
      .btnAdd{
        width: 100%;
      }
    }
    .btnGroup {
      justify-content: center;
      .btn {
        width: 100%;
      }
    }
  }
  
}
