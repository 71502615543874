.packets {
  width: 100%;
  background-color: #fff;
  .container {
    .row {
      padding: 26px 30px;
      flex-direction: column;
      gap: 10px;
      .homeBg {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin-top: 3%;
        img {
          width: 90%;
        }
      }
      .packetsHead {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        margin-bottom: 24px;
        padding: 0 12px;
        .packetsTitle {
          width: 50%;
          max-width: 300px;
          font-weight: 700;
          font-size: 30px;
          line-height: 1.35;
          color: #23272f;
        }
        .btnGroup {
          display: flex;
          justify-content: flex-end;
          width: 50%;
        }
      }
      .selectPacket {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-wrap: wrap;
        gap: 16px;
        padding-bottom: 32px;
        .businessBg {
          width: 32%;
          height: 467px;
          background-image: url(../../../images/packet-bg.png);
          background-size: cover;
          background-position: center center;
          background-repeat: no-repeat;
        }
        .packetCard {
          width: 32%;
          &:nth-child(1) {
            .cardBtn {
              background-color: #f5f5f5;
              color: #115eed;
            }
          }
        }
        .freeTrialCard {
          width: 32%;
        }
      }
    }
  }
}

// Screen width 1024px
@media (max-width: 1024px) {
  .packets {
    padding: 26px 56px;
    .container {
      .row {
        padding: 0 15px;
        .selectPacket {
          padding: 0;
          .businessBg {
            display: none;
          }
          .packetCard {
            width: 48%;
          }
          .freeTrialCard {
            width: 48%;
          }
        }
      }
    }
  }
}

// Screen width 768px
@media (max-width: 768px) {
  .packets {
    padding: 0 0 56px 0;
    .container {
      .row {
        padding: 0;
        .packetsHead {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          text-align: center;
          margin-top: 24px;
          gap: 24px;
          .packetsTitle {
            width: 100%;
            max-width: 100%;
          }
          .btnGroup {
            flex-direction: row;
            flex-wrap: nowrap;
            width: 100%;
          }
        }
        .selectPacket {
          padding: 0;
          .packetCard {
            width: 100%;
          }
          .freeTrialCard {
            width: 100%;
          }
        }
      }
    }
  }
}

@media (min-width: 1800px) {
  .packets {
    .container {
      .row {
        .packetsHead {
          padding: 26px 46px;
        }
      }
    }
  }
}
