.profileInfoEdit {
  .infoEditForm {
    .infoSkills {
      display: flex;
      gap: 10px;
      font-size: 14px;
      color: #979797;
    }
  }
  .videoEdit {
    .fullSize {
      justify-content: center;
      .btn {
        width: 50%;
        &.delete {
          background-color: #ed4611;
        }
      }
    }
  }
}
