@import "../../mixins";

.loginForm {
  width: 100%;
  height: 100%;
  .formTitle {
    font-weight: 500;
    font-size: 32px;
    color: #23272f;
    margin-bottom: 16px;
  }
  .question {
    color: #535861;
    margin-bottom: 32px;
    a {
      color: #115eed;
      font-weight: 400;
      transition: 0.3s ease;
      &:hover {
        opacity: 0.8;
      }
    }
  }
  .socialBtnGroup {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 16px;
    margin-bottom: 32px;
    .btnWithLogo {
      min-width: 188px;
      min-height: 40px;
      outline: 0;
      border: 1px solid #231f2012;
      padding: 10px 12px;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
      background: #ffffff;
      transition: 0.3s ease;
      cursor: pointer;
      &.linkedin {
        background: #0a66c2;
        .text {
          color: #ffffff;
        }
      }
      .icon {
        width: 20px;
        height: 20px;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
      .text {
        font-size: 14px;
        color: #23272f;
      }
      &:hover {
        transform: scale(1.05);
      }
      &:active {
        transform: scale(0.95);
      }
    }
  }
  .tabList {
    display: flex;
    align-items: center;
    margin-bottom: 44px;
    p {
      min-width: 195px;
      padding: 12px;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #ffffff;
      border-bottom: 2px solid #bebdc2;
      color: #23272f;
      cursor: pointer;
      transition: 0.3s ease;
      &:hover {
        color: #003fde;
      }
      &.active {
        border-bottom-color: #003fde;
      }
    }
  }
  .phoneBox {
    width: 100%;
    label {
      display: inline-block;
      font-size: 14px;
      color: #535861;
      margin-bottom: 12px;
    }
    .PhoneInput {
      .PhoneInputInput {
        @include formInput();
        font-size: 16px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        &.err {
          border-color: #ed4611;
        }
      }
      .PhoneInputCountry {
        background: #f2f4f8;
        margin-right: 0;
        padding: 10px;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }
    }
    .errorAlert {
      font-size: 14px;
      color: #ed4611;
      position: relative;
      padding-left: 26px;
      &::before {
        content: "";
        background-image: url(../../../images/icons/alert.svg);
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain;
        width: 20px;
        height: 20px;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }
  .inputBox {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin: 32px 0;
    position: relative;
    label {
      font-size: 14px;
      color: #535861;
    }
    .formInput {
      @include formInput();
      font-size: 16px;
      &.err {
        border-color: #ed4611;
      }
    }
    .errorAlert {
      font-size: 14px;
      color: #ed4611;
      position: relative;
      padding-left: 26px;
      &::before {
        content: "";
        background-image: url(../../../images/icons/alert.svg);
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain;
        width: 20px;
        height: 20px;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
    .eye {
      position: absolute;
      top: 50px;
      right: 16px;
      cursor: pointer;
    }
    .forgot {
      text-align: right;
      a {
        color: #115eed;
        font-size: 14px;
        font-weight: 400;
        line-height: 14px;
        transition: 0.3s ease;
        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
  .formBtn {
    margin-top: 32px;
    @include formBtn();
  }
}

@media (max-width: 768px) {
  .loginForm {
    .socialBtns {
      width: 100%;
      flex-wrap: wrap;
      a {
        width: 100%;
      }
    }
    .tabList {
      p {
        min-width: auto;
        width: 100%;
        font-size: 14px;
      }
    }
  }
}

@media (max-width: 512px) {
  .loginForm {
    .socialBtnGroup {
      flex-direction: column;
      & + div {
        width: 100%;
      }
      .btnWithLogo {
        width: 100%;
      }
    }
  }
}

@media (max-width: 368px) {
  .loginForm {
    .formTitle {
      font-size: 24px;
    }
    .question {
      font-size: 14px;
    }
    .tabList {
      p {
        font-size: 12px;
      }
    }
  }
}
