.loading {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999;
  background-color: #fff;
}
//   svg {
//     width: 240px;
//     height: auto;
//   }

//   @-webkit-keyframes animate-svg-fill-1 {
//     0% {
//       fill: transparent;
//     }

//     100% {
//       fill: rgb(35, 39, 47);
//     }
//   }

//   @keyframes animate-svg-fill-1 {
//     0% {
//       fill: transparent;
//     }

//     100% {
//       fill: rgb(35, 39, 47);
//     }
//   }

//   .svg-elem-1 {
//     -webkit-animation: animate-svg-fill-1 0.7s
//       cubic-bezier(0.47, 0, 0.745, 0.715) 0.8s both infinite;
//     animation: animate-svg-fill-1 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 0.8s
//       both infinite;
//   }

//   @-webkit-keyframes animate-svg-fill-2 {
//     0% {
//       fill: transparent;
//     }

//     100% {
//       fill: rgb(35, 39, 47);
//     }
//   }

//   @keyframes animate-svg-fill-2 {
//     0% {
//       fill: transparent;
//     }

//     100% {
//       fill: rgb(35, 39, 47);
//     }
//   }

//   .svg-elem-2 {
//     -webkit-animation: animate-svg-fill-2 0.7s
//       cubic-bezier(0.47, 0, 0.745, 0.715) 0.9s both infinite;
//     animation: animate-svg-fill-2 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 0.9s
//       both infinite;
//   }

//   @-webkit-keyframes animate-svg-fill-3 {
//     0% {
//       fill: transparent;
//     }

//     100% {
//       fill: rgb(35, 39, 47);
//     }
//   }

//   @keyframes animate-svg-fill-3 {
//     0% {
//       fill: transparent;
//     }

//     100% {
//       fill: rgb(35, 39, 47);
//     }
//   }

//   .svg-elem-3 {
//     -webkit-animation: animate-svg-fill-3 0.7s
//       cubic-bezier(0.47, 0, 0.745, 0.715) 1s both infinite;
//     animation: animate-svg-fill-3 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1s
//       both infinite;
//   }

//   @-webkit-keyframes animate-svg-fill-4 {
//     0% {
//       fill: transparent;
//     }

//     100% {
//       fill: rgb(35, 39, 47);
//     }
//   }

//   @keyframes animate-svg-fill-4 {
//     0% {
//       fill: transparent;
//     }

//     100% {
//       fill: rgb(35, 39, 47);
//     }
//   }

//   .svg-elem-4 {
//     -webkit-animation: animate-svg-fill-4 0.7s
//       cubic-bezier(0.47, 0, 0.745, 0.715) 1.1s both infinite;
//     animation: animate-svg-fill-4 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.1s
//       both infinite;
//   }

//   @-webkit-keyframes animate-svg-fill-5 {
//     0% {
//       fill: transparent;
//     }

//     100% {
//       fill: rgb(35, 39, 47);
//     }
//   }

//   @keyframes animate-svg-fill-5 {
//     0% {
//       fill: transparent;
//     }

//     100% {
//       fill: rgb(35, 39, 47);
//     }
//   }

//   .svg-elem-5 {
//     -webkit-animation: animate-svg-fill-5 0.7s
//       cubic-bezier(0.47, 0, 0.745, 0.715) 1.2000000000000002s both infinite;
//     animation: animate-svg-fill-5 0.7s cubic-bezier(0.47, 0, 0.745, 0.715)
//       1.2000000000000002s both infinite;
//   }

//   @-webkit-keyframes animate-svg-fill-6 {
//     0% {
//       fill: transparent;
//     }

//     100% {
//       fill: rgb(35, 39, 47);
//     }
//   }

//   @keyframes animate-svg-fill-6 {
//     0% {
//       fill: transparent;
//     }

//     100% {
//       fill: rgb(35, 39, 47);
//     }
//   }

//   .svg-elem-6 {
//     -webkit-animation: animate-svg-fill-6 0.7s
//       cubic-bezier(0.47, 0, 0.745, 0.715) 1.3s both infinite;
//     animation: animate-svg-fill-6 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.3s
//       both infinite;
//   }

//   @-webkit-keyframes animate-svg-fill-7 {
//     0% {
//       fill: transparent;
//     }

//     100% {
//       fill: rgb(17, 94, 237);
//     }
//   }

//   @keyframes animate-svg-fill-7 {
//     0% {
//       fill: transparent;
//     }

//     100% {
//       fill: rgb(17, 94, 237);
//     }
//   }

//   .svg-elem-7 {
//     -webkit-animation: animate-svg-fill-7 0.7s
//       cubic-bezier(0.47, 0, 0.745, 0.715) 1.4000000000000001s both infinite;
//     animation: animate-svg-fill-7 0.7s cubic-bezier(0.47, 0, 0.745, 0.715)
//       1.4000000000000001s both infinite;
//   }

//   @-webkit-keyframes animate-svg-fill-8 {
//     0% {
//       fill: transparent;
//     }

//     100% {
//       fill: rgb(17, 94, 237);
//     }
//   }

//   @keyframes animate-svg-fill-8 {
//     0% {
//       fill: transparent;
//     }

//     100% {
//       fill: rgb(17, 94, 237);
//     }
//   }

//   .svg-elem-8 {
//     -webkit-animation: animate-svg-fill-8 0.7s
//       cubic-bezier(0.47, 0, 0.745, 0.715) 1.5s both infinite;
//     animation: animate-svg-fill-8 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.5s
//       both infinite;
//   }

//   @-webkit-keyframes animate-svg-fill-9 {
//     0% {
//       fill: transparent;
//     }

//     100% {
//       fill: rgb(17, 94, 237);
//     }
//   }

//   @keyframes animate-svg-fill-9 {
//     0% {
//       fill: transparent;
//     }

//     100% {
//       fill: rgb(17, 94, 237);
//     }
//   }

//   .svg-elem-9 {
//     -webkit-animation: animate-svg-fill-9 0.7s
//       cubic-bezier(0.47, 0, 0.745, 0.715) 1.6s both infinite;
//     animation: animate-svg-fill-9 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.6s
//       both infinite;
//   }

//   @-webkit-keyframes animate-svg-fill-10 {
//     0% {
//       fill: transparent;
//     }

//     100% {
//       fill: rgb(17, 94, 237);
//     }
//   }

//   @keyframes animate-svg-fill-10 {
//     0% {
//       fill: transparent;
//     }

//     100% {
//       fill: rgb(17, 94, 237);
//     }
//   }

//   .svg-elem-10 {
//     -webkit-animation: animate-svg-fill-10 0.7s
//       cubic-bezier(0.47, 0, 0.745, 0.715) 1.7000000000000002s both infinite;
//     animation: animate-svg-fill-10 0.7s cubic-bezier(0.47, 0, 0.745, 0.715)
//       1.7000000000000002s both infinite;
//   }

//   @-webkit-keyframes animate-svg-fill-11 {
//     0% {
//       fill: transparent;
//     }

//     100% {
//       fill: rgb(18, 219, 99);
//     }
//   }

//   @keyframes animate-svg-fill-11 {
//     0% {
//       fill: transparent;
//     }

//     100% {
//       fill: rgb(18, 219, 99);
//     }
//   }

//   .svg-elem-11 {
//     -webkit-animation: animate-svg-fill-11 0.7s
//       cubic-bezier(0.47, 0, 0.745, 0.715) 1.8s both infinite;
//     animation: animate-svg-fill-11 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.8s
//       both infinite;
//   }

//   @-webkit-keyframes animate-svg-fill-12 {
//     0% {
//       fill: transparent;
//     }

//     100% {
//       fill: rgb(18, 219, 99);
//     }
//   }

//   @keyframes animate-svg-fill-12 {
//     0% {
//       fill: transparent;
//     }

//     100% {
//       fill: rgb(18, 219, 99);
//     }
//   }

//   .svg-elem-12 {
//     -webkit-animation: animate-svg-fill-12 0.7s
//       cubic-bezier(0.47, 0, 0.745, 0.715) 1.9000000000000001s both infinite;
//     animation: animate-svg-fill-12 0.7s cubic-bezier(0.47, 0, 0.745, 0.715)
//       1.9000000000000001s both infinite;
//   }

//   @-webkit-keyframes animate-svg-fill-13 {
//     0% {
//       fill: transparent;
//     }

//     100% {
//       fill: rgb(18, 219, 99);
//     }
//   }

//   @keyframes animate-svg-fill-13 {
//     0% {
//       fill: transparent;
//     }

//     100% {
//       fill: rgb(18, 219, 99);
//     }
//   }

//   .svg-elem-13 {
//     -webkit-animation: animate-svg-fill-13 0.7s
//       cubic-bezier(0.47, 0, 0.745, 0.715) 2s both infinite;
//     animation: animate-svg-fill-13 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2s
//       both infinite;
//   }

//   @-webkit-keyframes animate-svg-fill-14 {
//     0% {
//       fill: transparent;
//     }

//     100% {
//       fill: rgb(18, 219, 99);
//     }
//   }

//   @keyframes animate-svg-fill-14 {
//     0% {
//       fill: transparent;
//     }

//     100% {
//       fill: rgb(18, 219, 99);
//     }
//   }

//   .svg-elem-14 {
//     -webkit-animation: animate-svg-fill-14 0.7s
//       cubic-bezier(0.47, 0, 0.745, 0.715) 2.1s both infinite;
//     animation: animate-svg-fill-14 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.1s
//       both infinite;
//   }
// }
