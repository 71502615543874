.privateStatusModal {
  .privateDescList {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 0 36px;
    margin: 39px 0 45px 0;
    .descListItem {
      list-style-type: disc;
      font-size: 16px;
      color: #000000;
    }
  }
  .btnGroup {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 18px;
    .btn {
      width: 178px;
      height: 51px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 6px;
      border: 1px solid #6b9af4;
      font-weight: 500;
      background: #115eed;
      color: #ffffff;
      cursor: pointer;
      transition: 0.3s ease;
      &.cancel {
        color: #115eed;
        background: #ffffff;
      }
      &:hover {
        transform: scale(1.05);
      }
    }
  }
}

@media (max-width: 768px) {
  .privateStatusModal {
    .privateDescList {
      gap: 24px;
      padding: 0;
      padding-left: 10px;
      margin: 0;
      margin-bottom: 40px;
      .descListItem {
        font-size: 14px;
      }
    }
    .btnGroup {
      justify-content: center;
      flex-direction: column;
      .btn {
        width: 100%;
        gap: 16px;
      }
    }
  }
}
