.settingsSideBar {
  width: 100%;
  .settingList {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    .settingsListItem {
      width: 100%;
      background-color: #fff;
      border-bottom: 1px solid #f6f6f6;
      padding: 23px 16px;
      transition: all 0.3s ease;
      cursor: pointer;
      img{
        display: none;
      }
      &.active,
      &:hover {
        background-color: #0e4cbe;
        color: #fff;
        font-weight: 500;
      }
    }
  }
}

@media (max-width: 768px) {
  .settingsSideBar {
    .settingList {
      .settingsListItem {
        border-bottom: 1px solid #c9cacd;
        padding: 22px 16px;
        transition: all 0.3s ease;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;
        img{
          display: block;
        }
        &.active,
        &:hover {
          background-color: #fff;
          color: #000;
          font-weight: 600;
        }
      }
    }
  }
}
