@import "../mixins";

.header {
  width: 100%;
  position: fixed;
  padding: 8px;
  z-index: 999;
  margin-top: 8px;
  .container {
    .row {
      background-color: rgba(255, 255, 255, 0.75);
      backdrop-filter: blur(40px);
      border-radius: 16px;
      justify-content: space-between;
      align-items: center;
      padding-top: 16px;
      padding-bottom: 16px;
      .navBar {
        .navList {
          display: flex;
          align-items: center;
          gap: 32px;
          .navItem {
            cursor: pointer;
          }
          .signIn {
            @include upgradeBtn();
            width: 100px;
            padding: 8px 1px;
            // padding: 10px 24px;
            // background: transparent;
            // border: 1px solid #23272f;
            // border-radius: 4px;
            // font-weight: 500;
            // color: #23272f;
            // transition: 0.3s ease;
            // transition: all background 0.5s ease linear;
            // &:hover {
            //   background: #115eed;
            //   color: #ffffff;
            //   border-color:transparent ;
            // }
          }
        }
      }
    }
  }
}

// Screen width 768px
@media (max-width: 768px) {
  .header {
    width: 100%;
    position: fixed;
    padding: 0;
    top: 0;
    left: 0;
    right: 0;
    background: transparent;
    .container {
      .row {
        padding: 26px 0;
        .logo {
          width: 120px;
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
        .navBar {
          .navList {
            display: flex;
            gap: 8px;
            .navItem {
              display: none;
            }
            .signIn {
              padding: 6px 2px;
            }
            .selectLang {
              min-height: 38px;
              min-width: 38px;
            }
          }
        }
      }
    }
  }
}
