.sharedUserProfile {
  width: 100%;
  height: 100vh;
  background-color: #f1f5f7;
  .container {
    height: 100%;
    .row {
      height: 100%;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 25px;
      .bar {
        width: 100%;
        display: flex;
        justify-content: flex-start;
      }
    }
  }
}

@media (max-width: 468px) {
  .sharedUserProfile {
    .container {
      .row {
        padding: 26px 0;
      }
    }
  }
}
