.customSwitch {
  font-size: 17px;
  position: relative;
  display: inline-block;
  width: 42px;
  height: 24px;
  input {
    display: none;
    opacity: 0;
    width: 0;
    height: 0;
    &:disabled {
      & + .slider {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }
    &:checked {
      & + .slider::before {
        background-color: #ffffff;
        transform: translateX(18px);
      }
      & + .slider {
        background-color: #84da89;
      }
    }
    &:focus {
      & + .slider {
        box-shadow: 0 0 1px #84da89;
      }
    }
  }
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #d1d1d6;
    transition: 0.2s;
    border-radius: 30px;
    &::before {
      position: absolute;
      content: "";
      height: 20px;
      width: 20px;
      border-radius: 20px;
      left: 2px;
      bottom: 2px;
      background-color: #ffffff;
      transition: 0.4s;
    }
  }
}
