@use "../../../mixins";

.completeProfile {
  width: 100%;
  background-color: #ffa70010;
  padding: 16px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .leftSide {
    .title {
      font-size: 18px;
      font-weight: 500;
      color: #23272f;
      margin-bottom: 12px;
    }
    .info {
      font-size: 12px;
      font-weight: 400;
      color: #00000070;
    }
  }
  .btn {
    border: 0;
    outline: 0;
    background-color: #ffa700;
    font-size: 14px;
    color: #fff;
    font-weight: 500;
    padding: 10px 7px;
    border-radius: 6px;
    cursor: pointer;
    transition: 0.3s ease;
    &:hover {
      background-color: #ffa70090;
    }
  }
}

@media (max-width: 768px) {
  .completeProfile {
    flex-direction: column;
    gap: 12px;
    .leftSide {
      text-align: center;
      .title {
        margin-bottom: 0;
      }
    }
  }
}
