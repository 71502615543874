.editProfilePhoto {
  max-height: 500;
  overflow-y: auto;
  .editProfileComp {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    .infoArea {
      display: flex;
      gap: 10px;
      align-items: center;
      justify-content: center;
      p {
        font-size: 14px;
        font-weight: 400;
      }
    }
    .profilePhoto {
      width: 120px;
      height: 120px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50%;
      }
    }
    .errorMsg {
      font-size: 14px;
      font-weight: 500;
      text-align: center;
      color: #ed4611;
    }
    span {
      width: 100%;
      display: block;
      height: 1px;
      border: 1px;
      background-color: #e8e8e8;
    }
    .btnGroup {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      gap: 20px;
      flex-direction: column;
      .editBtn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        gap: 20px;
        .removeBtn {
          width: 20%;
          border: 1px solid #115eed80;
          outline: none;
          background-color: transparent;
          padding: 8px 24px;
          border-radius: 4px;
          display: flex;
          gap: 8px;
          align-items: center;
          justify-content: center;
          font-size: 14px;
          font-weight: 500;
          color: #115eed;
          cursor: pointer;
          transition: 0.3s ease;
          &:hover {
            color: #e8e8e8;
            background-color: #115eed;
            border-color: #e8e8e8;
            img {
              filter: invert(100%);
            }
          }
        }
        .saveBtn {
          width: 40%;
          border: 1px solid #115eed80;
          outline: none;
          background-color: #e8ecf7;
          border-radius: 4px;
          font-size: 14px;
          font-weight: 500;
          cursor: pointer;
          label {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 8px;
            width: 100%;
            padding: 8px 24px;
            color: #115eed;
            cursor: pointer;
          }
          input {
            display: none;
          }
          transition: 0.3s ease;
          &:hover {
            label {
              color: #e8e8e8;
            }
            background-color: #115eed;
            border-color: #e8e8e8;
            img {
              filter: invert(100%);
            }
          }
        }
      }
      .cancelBtn {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        .btnCancel {
          width: 35%;
          padding: 12px;
          border-radius: 4px;
          border: none;
          outline: none;
          background-color: #115eed08;
          cursor: pointer;
          transition: 0.3s ease;
          border: 1px solid transparent;
          &:hover {
            border-color: #115eed;
          }
        }
      }
    }
  }
}
