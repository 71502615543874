.notFound {
    width: 100%;
    height: 100vh;
    padding: 0 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    gap: 24px;
    a {
      color: #115eed;
      transition: 0.3s ease;
      &:hover {
        opacity: 0.8;
      }
    }
  }
  