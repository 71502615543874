.profileEditModal {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  .overlay {
    width: 100%;
    height: 100vh;
    background-color: #00000080;
    position: fixed;
    top: 0;
    left: 0;
  }
  .content {
    width: 100%;
    max-height: 80%;
    overflow-y: auto;
    // max-width: 874px;
    max-width: 560px;
    padding: 12px;
    background-color: #ffffff;
    border-radius: 8px;
    position: relative;
    z-index: 1;
    padding-bottom: 52px;
    .modalTitle {
      font-size: 20px;
      font-weight: 500;
      color: #000000;
      padding: 15px 0;
      margin-bottom: 24px;
    }
  }
}

@media (max-width: 728px) {
  .profileEditModal {
    position: fixed;
    top: 0;
    left: 0;
    align-items: flex-end;
    .content {
      overflow-y: auto;
      max-height: 90%;
      padding: 16px;
      border-radius: 20px;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      z-index: 1;
      transition: 0.3s ease;
      padding-bottom: 60px;
      .modalTitle {
        font-size: 16px;
        padding: 8px 0;
        margin-bottom: 12px;
      }
    }
  }
}
