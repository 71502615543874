.statistic {
  width: 100%;
  padding: 56px 26px;
  .container {
    .row {
      padding: 0;
      justify-content: center;
      flex-wrap: wrap;
      gap: 16px;
      .statisticCard {
        width: 32%;
      }
    }
  }
}

@media (max-width: 1024px) {
  .statistic {
    padding: 56px 66px;
    .container {
      .row {
        .statisticCard {
          width: 100%;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .statistic {
    padding: 56px 0;
  }
}
