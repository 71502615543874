.upgradePackSubscribe {
  width: 100%;
  position: fixed;
  background: #ffffff26;
  bottom: 0;
  left: 0;
  backdrop-filter: blur(5px);
  z-index: 10;
  .row {
    justify-content: space-between;
    align-items: center;
    padding: 16px 32px 16px 336px;
    .text {
      line-height: 16px;
      a {
        color: #115eed;
      }
    }
    .btn {
      line-height: 16px;
      color: #ffffff;
      background: #231f20;
      padding: 16px;
      max-width: 211px;
      width: 100%;
      text-align: center;
      border-radius: 24px;
    }
  }
}

/* ------------------------------- Responsive ------------------------------- */
@media (max-width: 768px) {
  .upgradePackSubscribe {
    bottom: 76px;
    .row {
      padding: 16px 32px;
      .text {
        font-size: 14px;
      }
      .btn {
        padding: 12px;
        max-width: 180px;
        font-size: 14px;
      }
    }
  }
}
@media (max-width: 380px) {
  .upgradePackSubscribe {
    .row {
      .btn {
        padding: 10px;
        max-width: 150px;
        font-size: 12px;
      }
    }
  }
}
