.mobileLanguageSelect {
  .mobileOverlay {

    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: transparent;
    z-index: -100;
    opacity: 0;
    visibility: hidden;
    &.visible {
      visibility: visible;
      background: #000;
      opacity: 0.7;
      z-index: 1000000;
    }
  }
  .languageSelect {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #fff;
    border: 1px solid rgba(35, 39, 47, 0.1);
    z-index: 10000000;
    padding: 0 24px 24px;
    border-radius: 0;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    transition: 0.15s cubic-bezier(0.4, 0, 0.2, 1);
    opacity: 0;
    visibility: hidden;
    transform: scale(0);
    transform-origin: bottom center;
    padding-bottom: 60px;

    &.acitve {
      opacity: 1;
      visibility: visible;
      transform: scale(1);
      transform-origin: bottom center;
    }

    .langArea {
      display: flex;
      flex-direction: column;
      gap: 32px;

      .langs {
        cursor: pointer;
        display: flex;
        gap: 16px;
        transition: 0.15s cubic-bezier(0.4, 0, 0.2, 1);

        &:active {
          transform: scale(0.9);
        }
      }
    }
    .line {
      display: block;
      width: 56px;
      height: 4px;
      border-radius: 7px;
      background: #c3c5d4;
      margin: 12px auto;
    }
  }
}
