@import "../mixins";

.dashboardHeader {
  width: 100%;
  min-height: 78px;
  padding: 14px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #cbddeb;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: #fff;
  .mobileHeader {
    width: 100%;
    background: #e9ecf3;
    border-radius: 10px;
    display: none;
    p {
      line-height: 20px;
      color: #5b667c;
    }
  }
  .outsildeClickOverlay {
    width: 100%;
    height: 100vh;
    background-color: transparent;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    display: none;
    &.active {
      display: block;
    }
  }
  .searchBar {
    width: 35%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #ffffff;
    border: 1px solid rgba(17, 94, 237, 0.25);
    border-radius: 20px;
    padding: 0 16px;
    position: relative;
    &.error {
      border-color: #ed4611;
    }
    &:focus-within {
      border-color: #00aaff;
    }
    .errorPopup {
      color: #ed4611;
      position: absolute;
      bottom: -20px;
      left: calc(50% - 72px);
      font-size: 14px;
    }
    button {
      border: 0;
      outline: 0;
      background-color: #fff;
      width: 20px;
      height: 20px;
      cursor: pointer;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    input {
      width: 100%;
      padding: 12px 0;
      height: 100%;
      border: 0;
      outline: 0;
      font-size: 14px;
      &::placeholder {
        color: rgba(35, 39, 47, 0.25);
      }
    }
  }
  .userArea {
    display: flex;
    align-items: center;
    gap: 16px;
    .shareIcon {
      height: 32px;
      padding: 4px 12px;
      border-radius: 16px;
      border: 1px solid rgba(35, 39, 47, 0.1215686275);
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 8px;
      cursor: pointer;
      transition: 0.3s ease;
      margin-right: 14px;
      &:hover {
        border-color: #115eed;
      }
      .icon {
        width: 24px;
        height: 24px;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .shareInfo {
        color: #23272f;
      }
    }
    .signIn {
      @include upgradeBtn();
      // padding: 10px 24px;
      // background: transparent;
      // border: 1px solid #23272f;
      // border-radius: 4px;
      // font-weight: 500;
      // color: #23272f;
      // transition: 0.3s ease;
      // transition: all background 0.5s ease linear;
      // &:hover {
      //   background: #115eed;
      //   color: #ffffff;
      // }
    }
    .account {
      cursor: pointer;
      position: relative;
      .avatar {
        width: 32px;
        height: 32px;
        min-width: 32px;
        min-height: 32px;
        margin-right: 8px;
        border-radius: 100%;
        position: relative;
        border: 1px solid #fff;
        transition: .3s ease;
        &.isDefault {
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            border-radius: 0;
          }
        }
        .defaultIcon {
          width: 24px;
          height: 24px;
          object-fit: contain;
        }
        // &.avatarPro {
        //   border: 2px solid #a528ff;
        // }
        background-color: #fff;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: top center;
          border-radius: 100%;
        }
        // .badge {
        //   position: absolute;
        //   bottom: 0;
        //   right: 0;
        //   padding: 4px 10px;
        //   border-radius: 8px;
        //   background: linear-gradient(
        //     91.74deg,
        //     #a528ff 12.93%,
        //     #1d42ce 101.26%
        //   );
        //   color: #ffffff;
        //   font-size: 11px;
        //   font-weight: 500;
        //   &.defaultBadgePosition {
        //     padding: 2px 4px;
        //   }
        // }
      }
      &:hover {
        .avatar {
          border-color: #115eed;
        }
      }
      .title {
        color: #272931;
        img {
          transition: transform 0.3s ease;
          transform: rotate(0);
        }
        &.up {
          img {
            transform: rotate(180deg);
          }
        }
      }
      .accountMenu {
        display: flex;
        opacity: 0;
        visibility: hidden;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        gap: 4px;
        position: absolute;
        top: 100%;
        right: 0;
        padding: 8px;
        min-width: 304px;
        background: #ffffff;
        box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.1);
        border-radius: 12px;
        transition: display 0.3s ease;
        transition: opacity 0.3s ease;
        z-index: 1000;
        .accountMenuItem {
          width: 100%;
          a {
            width: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 10px;
            padding: 10px 12px;
            color: #000;
            background: #fff;
            transition: 0.3s ease;
            border-radius: 8px;
            img {
              width: 28px;
              height: 28px;
              border-radius: 50%;
              object-fit: cover;
            }
            &:hover {
              background: #115eed;
              color: #fff;
              .logOutIcon {
                filter: invert(100%);
              }
            }
          }
          &.goPro {
            a {
              background: #115eed;
              color: #fff;
              &:hover {
                img {
                  filter: invert(0%);
                }
              }
            }
          }
        }
        &.open {
          // display: flex;
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .dashboardHeader {
    width: 100%;
    min-height: 78px;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #cbddeb;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    background-color: #fff;
    display: none;
    &.shared {
      display: flex;
    }
    .mobileHeader {
      width: 100%;
      background: #e9ecf3;
      border-radius: 10px;
      text-align: center;
      padding: 12px 0;
      display: none;
      a {
        line-height: 20px;
        color: #5b667c;
      }
    }
    .logo {
      display: none;
    }
    .searchBar {
      display: none;
    }
    .userArea {
      display: none;
    }
    &.shared {
      .logo {
        display: inline-block;
      }
      .searchBar {
        display: none;
      }
      .userArea {
        display: inline-block;
        .selectLang {
          display: none;
        }
      }
      // .userArea {
      //   width: 100%;
      //   display: flex;
      //   justify-content: space-between;
      // }
    }
  }
}
