.sharedUserCard {
  width: 100%;
  max-width: 346px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 12px;
  .background {
    width: 100%;
    height: 50%;
    border-radius: 12px;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #115eed;
    .avatar {
      width: 96px;
      height: 96px;
      min-width: 96px;
      min-height: 96px;
      border-radius: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #ffffff;
      transform: translateY(50px);
      border: 5px solid #ffffff;
      position: relative;
      &.isDefault {
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          border-radius: 0;
        }
      }
      .defaultIcon {
        width: 24px;
        height: 24px;
        object-fit: contain;
      }
      &.avatarPro {
        border: 2px solid #a528ff;
        outline: 3px solid #ffffff;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: top center;
        border-radius: 100%;
      }
      .badge {
        position: absolute;
        bottom: 0;
        right: 0;
        padding: 4px 10px;
        border-radius: 8px;
        background: linear-gradient(91.74deg, #a528ff 12.93%, #1d42ce 101.26%);
        color: #ffffff;
        font-size: 11px;
        font-weight: 500;
        &.defaultBadgePosition {
          padding: 2px 4px;
        }
      }
    }
    // .userImg {
    //   width: 96px;
    //   height: 96px;
    //   display: flex;
    //   justify-content: center;
    //   align-items: center;
    //   border-radius: 50%;
    //   background-color: #115eed;
    //   transform: translateY(50px);
    //   border: 5px solid #ffffff;
    //   img {
    //     width: 48px;
    //     height: 48px;
    //     object-fit: cover;
    //     border-radius: 50%;
    //   }
    // }
    // .fullSize {
    //   img {
    //     width: 100%;
    //     height: 100%;
    //   }
    // }
  }
  .cardInfo {
    width: 100%;
    padding: 48px;
    .userInfo {
      color: #23272f;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin: 16px 0 24px 0;
      height: auto !important;
      .userName {
        font-size: 20px;
        font-weight: 500;
      }
      .boxCount {
        width: 150px;
        border-radius: 14px;
        background-color: rgba(17, 94, 237, 0.06);
        gap: 10px;
        padding: 8px 16px;
        text-align: center;
        color: #115eed;
        margin: 12px 0 24px 0;
      }
      .phoneNumber {
        font-size: 24px;
        font-weight: 500;
      }
    }
    .addUserBtn,
    .removeFromBox {
      width: 100%;
      padding: 16px 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      background-color: #115eed;
      transition: 0.3s ease;
      border-radius: 4px;
      cursor: pointer;
      img {
        width: 18px;
        height: 18px;
        object-fit: contain;
      }
      span {
        color: #fff;
        font-weight: 500;
      }
      &:hover {
        opacity: 0.8;
      }
    }
    .removeFromBox {
      background-color: #ed4611;
    }
  }
}

@media (max-width: 468px) {
  .sharedUserCard {
    padding: 24px !important;
    .cardInfo {
      padding: 24px;
      .userInfo {
        margin: 0 auto;
        margin-top: 30px;
        .userName {
          font-size: 14px;
        }
        .boxCount {
          margin: 6px auto;
        }
        .phoneNumber {
          font-size: 14px;
        }
      }
      .addUserBtn {
        font-size: 14px !important;
        margin-top: 12px;
      }
    }
  }
}
