.profileFeaturedSection {
  width: 100%;
  max-width: 619px;
  background-color: #ffffff;
  border: 1px solid #cbddeb50;
  border-radius: 8px;
  padding: 24px 30px;
  position: relative;
  .topSide {
    display: flex;
    justify-content: space-between;
    .leftSide {
      display: flex;
      align-items: center;
      margin-bottom: 24px;
      gap: 16px;
      .title {
        font-size: 20px;
        font-weight: 500;
        color: #23272f;
      }
      .addIcon {
        display: flex;
        gap: 6px;
        align-items: center;
        background-color: #e7effd;
        border-radius: 17px;
        padding: 8px 12px;
        transition: 0.3s ease;
        cursor: pointer;
        &:hover {
          background-color: #115eed50;
        }
        img {
          width: 16px;
          height: 16px;
          object-fit: cover;
        }
        span {
          font-size: 12px;
          font-weight: 400;
          color: #115eed;
        }
      }
    }
    .rightSide {
      display: flex;
      gap: 8px;
      .editIcon {
        width: 26px;
        height: 26px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #e7effd50;
        padding: 5px;
        transition: 0.3s ease;
        cursor: pointer;
        img {
          width: 16px;
          height: 16px;
          object-fit: cover;
        }
        &:hover {
          background-color: #e7effd;
        }
      }
      .paginationIcon {
        width: 24px;
        height: 24px;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }
  .bottomSide {
    position: relative;
    .mySwiper {
      .swiper-slide {
        width: 260px;
        height: 244px;
        margin-bottom: 12px;
        .slideItem {
          width: 100%;
          height: 244px;
          box-shadow: 2px 2px 10px #00000010;
          padding: 5px;
          border-radius: 12px;
          .slideImg {
            width: 100%;
            height: 150px;
            border-radius: 6px;
            margin-bottom: 16px;
            display: inline-block;
            border-top-left-radius: 12px;
            border-top-right-radius: 12px;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              object-position: top center;
              border-radius: 6px;
              border-top-left-radius: 12px;
              border-top-right-radius: 12px;
            }
          }
          .info {
            .title {
              margin-bottom: 12px;
              display: flex;
              justify-content: space-between;
              align-items: center;
              p {
                font-weight: 500;
                color: #23272f;
                font-size: 16px;
              }
              .options {
                width: 24px;
                height: 24px;
                cursor: pointer;
                .optionMenu {
                  position: absolute;
                  bottom: 0;
                  right: 30px;
                  border-radius: 4px;
                  min-width: 100px;
                  z-index: 99;
                  background-color: #fff;
                  display: none;
                  box-shadow: 3px 3px 5px #00000050;
                  &.isOpen {
                    display: block;
                  }
                  .optionMenuItem {
                    min-width: 100px;
                    padding: 6px 8px;
                    transition: 0.3s ease;
                    &:hover {
                      color: #115eed;
                      &.del {
                        color: red;
                      }
                    }
                  }
                }
                &:hover {
                  img {
                    filter: invert(50%);
                  }
                }
                img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                  transition: 0.3s ease;
                }
              }
            }
            .desc {
              font-weight: 400;
              color: #23272f;
              font-size: 14px;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
      }
    }
    .bottomAddIcon {
      display: none;
    }
  }
}

@media (max-width: 425px) {
  .profileFeaturedSection {
    padding: 12px 6px;
    .topSide {
      .leftSide {
        .title {
          font-size: 16px;
        }
        .addIcon {
          display: none;
        }
      }
    }
    .bottomSide {
      .bottomAddIcon {
        max-width: 100px;
        display: flex;
        gap: 6px;
        align-items: center;
        background-color: #e7effd;
        border-radius: 17px;
        padding: 6px 10px;
        transition: 0.3s ease;
        cursor: pointer;
        &:hover {
          background-color: #115eed50;
        }
        img {
          width: 16px;
          height: 16px;
          object-fit: cover;
        }
        span {
          font-size: 12px;
          font-weight: 400;
          color: #115eed;
        }
      }
    }
  }
}
