@mixin primaryBtn() {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: #115eed;
  padding: 16px 48px;
  border-radius: 4px;
  color: #fff;
  border: 0;
  outline: 0;
  cursor: pointer;
  transition: 0.3s ease;
  span {
    color: #fff;
    font-style: italic;
    font-weight: 500;
    transition: 0.3s ease;
  }
  &:hover {
    background: #0e4cbe;
  }
}

@mixin secondaryBtn() {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: #fff;
  padding: 16px 48px;
  border-radius: 4px;
  color: #115eed;
  border: 0;
  outline: 0;
  cursor: pointer;
  transition: 0.3s ease;
  span {
    color: #115eed;
    font-style: italic;
    font-weight: 500;
    transition: 0.3s ease;
  }
  &:hover {
    background: #88acf0;
    span {
      color: #fff;
    }
  }
}

@mixin formBtn() {
  width: 100%;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;
  outline: 0;
  background: radial-gradient(
    79.25% 295.31% at 20.95% 0%,
    #115eed 0%,
    #27e574 100%
  );

  color: #fff;
  border-radius: 8px;
  transition: 0.3s ease linear;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  transition: 0.3s ease;
  &:hover {
    opacity: 0.5;
  }
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

@mixin formInput() {
  border: 0;
  outline: 0;
  border: 1px solid rgba(201, 202, 205, 0.5);
  border-radius: 4px;
  padding: 14px 16px;
  background: #ffffff;
  color: #23272f;
  font-size: 14px;
  &:focus {
    border: 1px solid #115eed;
  }
}

@mixin formBtnOldDesign() {
  width: 100%;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;
  outline: 0;
  background: #12db63;
  color: #fff;
  border-radius: 6px;
  transition: 0.3s ease;
  cursor: pointer;
  font-size: 16px;
  font-weight: 16px;
  font-weight: 600;
  &:hover {
    opacity: 0.8;
  }
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

@mixin upgradeBtn() {
  border: 0;
  outline: 0;
  width: 100%;
  height: 38px;
  padding: 1px;
  cursor: pointer;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(
      262.25deg,
      #27e574 0%,
      rgba(18, 219, 99, 0) 55.99%
    ),
    linear-gradient(97.75deg, #115eed 0%, rgba(17, 94, 237, 0) 55.99%);
  box-shadow: 0px 2px 1px 0px #115eed;
  transition: all 0.3s ease linear;
  p {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 36px;
    background-color: #ffffff;
    color: #115eed;
    font-weight: 500;
    font-size: 14px;
    text-align: center;
    border-radius: 8px;
  }
  transition: 0.3s ease;
  &:hover {
    box-shadow: 0px 4px 2px 0px #115eed;
    // background-image: linear-gradient(
    //     422.25deg,
    //     #27e574 0%,
    //     rgba(18, 219, 99, 0) 55.99%
    //   ),
    //   linear-gradient(277.75deg, #115eed 0%, rgba(17, 94, 237, 0) 55.99%);
  }
}

.customInput {
  display: flex;
  flex-direction: column;
  gap: 10px;
  label {
    font-size: 14px;
    font-weight: 400;
    color: #000000;
    vertical-align: super;
    span {
      color: red;
    }
  }
  input {
    border: 1px solid #c9cacd50;
    outline: 0;
    padding: 18px 24px;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 400;
    color: #000000;
    ::placeholder {
      font-size: 14px;
      font-weight: 400;
      color: #c9cacd50;
    }
    :disabled {
      opacity: 0.5;
    }
  }
}

.customInputWithIcon {
  display: flex;
  flex-direction: column;
  gap: 12px;
  label {
    font-size: 14px;
    font-weight: 400;
    color: #000000;
    vertical-align: super;
    span {
      color: red;
    }
  }
  .inputBox {
    display: flex;
    align-items: center;
    width: 100%;
    position: relative;
    input {
      width: 100%;
      border: 1px solid #c9cacd50;
      outline: 0;
      padding: 18px 24px;
      border-radius: 4px;
      font-size: 14px;
      font-weight: 400;
      color: #000000;
      ::placeholder {
        font-size: 14px;
        font-weight: 400;
        color: #c9cacd50;
      }
      :disabled {
        opacity: 0.5;
      }
    }
    .iconItem {
      position: absolute;
      top: 18px;
      right: 24px;
      width: 24px;
      height: 24px;
      &.clickable {
        cursor: pointer;
        &:hover {
          opacity: 0.8;
        }
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}

.customTextArea {
  display: flex;
  flex-direction: column;
  gap: 12px;
  label {
    font-size: 14px;
    font-weight: 400;
    color: #000000;
  }
  textarea {
    resize: none;
    border: 1px solid #c9cacd50;
    outline: 0;
    padding: 18px 24px;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 400;
    color: #000000;
    ::placeholder {
      font-size: 14px;
      font-weight: 400;
      color: #c9cacd50;
    }
    :disabled {
      opacity: 0.5;
    }
  }
}
