.profileInfo {
  padding: 16px 0 0 0;
  .title {
    width: 100%;
    display: flex;
    justify-content: space-between;
    div {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 4px;
      h2 {
        font-size: 18px;
        font-weight: 500;
        color: #23272f;
        margin: 0 !important;
      }
      .username {
        margin-bottom: 12px;
      }
    }
    .editIcon {
      width: 26px;
      height: 26px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #e7effd50;
      padding: 5px;
      transition: 0.3s ease;
      cursor: pointer;
      z-index: 1;
      img {
        width: 16px;
        height: 16px;
        object-fit: cover;
      }
      &:hover {
        background-color: #e7effd;
      }
    }
  }
  .description {
    display: flex;
    flex-direction: column;
    gap: 12px;
    .boxCount {
      font-size: 14px;
      font-weight: 600;
      color: #115eed;
      span {
        font-size: 14px;
        font-weight: 400;
        color: #115eed;
      }
    }
    .location {
      display: flex;
      align-items: center;
      gap: 4px;
      img {
        width: 18px;
        height: 18px;
        object-fit: cover;
      }
      a {
        font-size: 14px;
        font-weight: 500;
        color: #003fde;
      }
    }
    .bio {
      display: flex;
      justify-content: space-between;
      p {
        max-width: 437px;
        font-size: 14px;
        font-weight: 400;
        color: #23272f;
      }
    }
    .addBtn {
      border: 0;
      outline: 0;
      padding: 13px 8px;
      background: #115eed;
      border-radius: 4px;
      font-weight: 500;
      color: #ffffff;
      transition: 0.3s ease;
      border: 1px solid transparent;
      text-align: center;
      cursor: pointer;
      text-transform: capitalize;
      &:hover {
        border-color: #1155ed;
        background-color: #fff;
        color: #1155ed;
      }
      &.added {
        background-color: #23272f50;
        &:hover {
          border-color: transparent;
          background-color: #23272f50;
          color: #ffffff;
          cursor: not-allowed;
        }
      }
    }
  }
}

@media (max-width: 425px) {
  .profileInfo {
    .title {
      width: 100%;
      display: flex;
      align-items: flex-start;
      h2 {
        font-size: 20px;
        font-weight: 500;
        color: #23272f;
      }
      .boxCount {
        font-size: 14px;
        font-weight: 600;
        color: #115eed;
      }
      .editIcon {
        width: 26px;
        height: 26px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #e7effd50;
        padding: 5px;
        transition: 0.3s ease;
        cursor: pointer;
        z-index: 1;
        img {
          width: 16px;
          height: 16px;
          object-fit: cover;
        }
        &:hover {
          background-color: #e7effd;
        }
      }
    }
    .description {
      .username {
        display: none;
      }
      .location {
        display: flex;
        align-items: center;
        gap: 4px;
        text-decoration: underline;
        text-decoration-color: #003fde;
        img {
          width: 18px;
          height: 18px;
          object-fit: cover;
        }
        a {
          font-size: 12px;
          font-weight: 500;
          color: #003fde;
        }
      }
      .bio {
        display: flex;
        justify-content: space-between;
        p {
          max-width: 437px;
          font-size: 14px;
          font-weight: 400;
          color: #23272f;
        }
      }
    }
  }
}

@media (max-width: 264px) {
  .profileInfo {
    .title {
      flex-direction: column;
    }
  }
}
