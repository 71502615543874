.changePlan {
  width: 100%;
  background-color: #f3f7ff;
  .row {
    padding: 50px 50px 50px 117px;
    flex-direction: column;
    gap: 25px;
    .aboutBucard{
      width: 100%;
      display: flex;
      gap: 20px;
      padding: 20px;
      .ourUsers{
        display: flex;
        background-color: #fff;
        padding: 24px;
        gap: 31px;
        border-radius: 8px;
        width: 100%;
        .icon{
          background-color: #DFE8FA;
          display: flex;
          border-radius: 8px;
        }
        .info{
          display: flex;
          flex-direction: column;
          justify-content: center;
          gap: 10px;
          p{
            color: #23272F;
            font-size: 20px;
            font-weight: 600;
            line-height: 20px;
          }
          span{
            color: #535861;
            font-size: 16px;
            font-weight: 400;
          }
        }
      }
    }
    .whatIsBucard{
      display: flex;
      background-color: #fff;
      padding: 20px;
      border-radius: 8px;
      .whatIsImg{
        width: 100%;
        img{
          width: 100%;
        }
      }
      .whatIsInfo{
        display: flex;
        flex-direction: column;
        gap: 25px;
        padding: 25px;
        h4{
          font-size: 32px;
          font-weight: 500;
          line-height: 32px;
          color: #000;
        }
        p{
          font-size: 18px;
          color:#535861 ;
          font-weight: 400;
        }
        .infoIcon{
          display: flex;
          gap: 15px;
        }
      }
    }
    .bucardBiznes{
      background-color:#372B5E ;
      display: flex;
      padding: 20px;
      border-radius: 8px;
      .biznesImg{
        width: 100%;
        img{
          width: 100%;
        }
      }
      .biznesInfo{
        display: flex;
        flex-direction: column;
        gap: 25px;
        padding: 25px;
        h4{
          font-size: 32px;
          font-weight: 500;
          line-height: 32px;
          color: #fff;
        }
        p{
          font-size: 18px;
          color:#fff ;
          font-weight: 400;
        }
        .infoIcon{
          display: flex;
          gap: 15px;
        }
      }
    }
    // .leftSide {
    //   width: 50%;
    //   padding: 56px 0;
    //   .tabTitle {
    //     font-weight: 700;
    //     font-size: 32px;
    //     color: #23272f;
    //     margin-bottom: 32px;
    //   }
    //   .tabList {
    //     width: 100%;
    //     padding: 0 30px;
    //     display: none;
    //     flex-direction: column;
    //     gap: 24px;
    //     align-items: flex-start;
    //     justify-content: center;
    //     margin-top: 48px;
    //     &.active {
    //       display: flex;
    //     }
    //     .tabItem {
    //       line-height: 1.5;
    //       color: #535861;
    //       position: relative;
    //       text-align: left;
    //       margin-left: 20px;
    //       &::after {
    //         content: "";
    //         display: inline-block;
    //         width: 24px;
    //         height: 24px;
    //         background-image: url(../../../images/icons/check.svg);
    //         position: absolute;
    //         top: 0;
    //         left: -50px;
    //       }
    //     }
    //   }
    // }
    // .rightSide {
    //   width: 50%;
    //   background: url(../../../images/changePlan-bg.png) no-repeat center center;
    //   background-size: cover;
    //   transition: 0.3s ease;
    //   &.active {
    //     background: url(../../../images/business-bg.png) no-repeat center center;
    //     background-size: cover;
    //     transition: 0.3s ease;
    //   }
    // }
  }
}

// Screen width 768px
@media (max-width: 768px) {
  .changePlan {
    .row {
      padding: 0;
      flex-wrap: wrap;
      .aboutBucard{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .ourUsers{
          width: 100%;
        }
      }
      .whatIsBucard{
        flex-direction: column-reverse;
        .whatIsInfo{
          p{
            font-size: 15px;
          }
        }
      }
      .bucardBiznes{
        flex-direction: column;
        .biznesInfo{
          p{
            font-size: 15px;
          }
        }
      }
      // .leftSide {
      //   width: 100%;
      //   padding: 56px 26px;
      //   text-align: center;
      //   .tabList {
      //     .tabItem {
      //       font-size: 14px;
      //       padding-right: 0;
      //     }
      //   }
      // }
      // .rightSide {
      //   width: 100%;
      //   height: 375px;
      //   background: url(../../../images/changePlan-bg-responsive.png) no-repeat
      //     center center;
      //   background-size: cover;
      //   &.active {
      //     background: url(../../../images/business-bg-responsive.png) no-repeat
      //       center center;
      //     background-size: cover;
      //     transition: 0.3s ease;
      //   }
      // }
    }
  }
}

// Screen width 368px
@media (max-width: 368px) {
  .changePlan {
    .row {
      .aboutBucard{
        .ourUsers{
          width: 100%;
        }
      }
      // .leftSide {
      //   .tabList {
      //     .tabItem {
      //       font-size: 12px;
      //       padding: 0;
      //       &::after {
      //         width: 18px;
      //         height: 18px;
      //         background-size: contain;
      //       }
      //     }
      //   }
      // }
      // .rightSide {
      //   width: 100%;
      // }
    }
  }
}

@media (min-width: 1800px) {
  .changePlan {
    .row {
      width: 94%;
      margin: 0 auto;
      .leftSide {
        .tabList {
          .tabItem {
            font-size: 26px;
          }
        }
      }
      .rightSide {
        background-position: top center;
      }
    }
  }
}
