.contactInformationsTab {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 14px;
  .contactBox {
    width: 100%;
    border: 1px solid #efefef;
    border-radius: 4px;
    background-color: #fff;
    padding: 36px 32px;
  }
  .userInfoInputs {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 57px;
    min-height: 196px;
    .customInput {
      width: 100%;
      border-color: #c9cacd80;
      input {
        &::placeholder {
          color: #c9cacd;
        }
      }
    }
  }
  .contacts {
    width: 100%;
    .contactBoxTitle {
      display: flex;
      justify-content: space-between;
      align-items: center;
      h3 {
        font-size: 16px;
        color: #535861;
        font-weight: 400;
      }
      .addContactBtn {
        border: 0;
        outline: 0;
        background-color: #e7effd;
        border: 1px solid transparent;
        padding: 8px 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 6px;
        border-radius: 17px;
        transition: 0.3s ease;
        cursor: pointer;
        img {
          width: 16px;
          height: 16px;
          object-fit: contain;
        }
        span {
          font-size: 14px;
          color: #115eed;
        }
        &:hover {
          border-color: #115eed;
          background-color: #fff;
        }
      }
    }
    .nums {
      width: 100%;
      padding: 21.5px 0;
      display: flex;
      gap: 10px;
      border-bottom: 1px solid #e4e5e638;
      .title {
        width: 10%;
        color: #8f9298;
        font-weight: 500;
        font-size: 14px;
      }
      .numsList {
        width: 90%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 11px;
        .numsListItem {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          gap: 26px;
          .head,
          .foot {
            width: 50%;
            display: flex;
            justify-content: space-between;
            align-items: center;
          }
          .phone {
            color: #535861;
            font-size: 14px;
          }
          .status {
            font-size: 14px;
            color: #12db63;
            &.unverified {
              color: #ff0000;
            }
          }
          .changeBtn {
            border: 0;
            outline: 0;
            background-color: #e7effd;
            border: 1px solid transparent;
            border-radius: 24px;
            padding: 4px 18px;
            color: #115eed;
            font-size: 14px;
            text-align: center;
            transition: 0.3s ease;
            cursor: pointer;
            &.disabled {
              opacity: 0.3;
            }
            &:hover {
              background-color: #fff;
              border-color: #115eed;
            }
          }
          .trashIcon {
            width: 18px;
            height: 18px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            transition: 0.3s ease;
            &:hover {
              opacity: 0.8;
            }
          }
        }
      }
    }
  }
  .socials {
    .socialsBoxTitle {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 24px;
      h3 {
        font-size: 16px;
        color: #535861;
        font-weight: 400;
      }
    }
    .socialList {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      column-gap: 28px;
      row-gap: 16px;
      .customInput {
        width: 47%;
        color: #535861;
        font-size: 14px;
        label {
          color: #535861a6;
          font-size: 14px;
        }
      }
    }
    .addNewLink {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-top: 24px;
      button {
        border: 0;
        outline: 0;
        background-color: #ffffff;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 12px;
        cursor: pointer;
        img {
          width: 20px;
          height: 20px;
          object-fit: contain;
        }
        span {
          font-size: 14px;
          line-height: 14px;
          color: #535861;
          transition: 0.3s ease;
        }
        &:hover {
          span {
            color: #115eed;
          }
        }
      }
    }
  }
  .saveButton {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .contactInformationsTab {
    .contactBox {
      padding: 24px 16px;
    }
    .userInfoInputs {
      flex-direction: column;
      gap: 16px;
    }
    .contacts {
      width: 100%;
      .nums {
        justify-content: space-between;
        gap: 0;
        .numsList {
          width: auto;
        }
        &.others {
          flex-direction: column;
          .title {
            margin-bottom: 16px;
          }
          .numsList {
            width: auto;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            gap: 11px;
            .numsListItem {
              width: 100%;
              display: flex;
              justify-content: space-between;
              align-items: center;
              flex-direction: column;
              gap: 12px;
              .head,
              .foot {
                width: 100%;
                display: flex;
                justify-content: space-between;
              }
              .changeBtn {
                background-color: transparent;
                border: none;
                border-radius: 0;
                padding: 0;
                text-decoration: underline;
                font-weight: 500;
                &:hover {
                  background-color: transparent;
                  border-color: transparent;
                }
              }
            }
          }
        }
      }
    }
    .socials {
      .socialList {
        flex-wrap: nowrap;
        flex-direction: column;
        .customInput {
          width: 100%;
        }
      }
    }
  }
}
