.contactItem {
  margin-bottom: 24px;
  .cardHead {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
    .cardTitle {
      display: flex;
      gap: 12px;
      img {
        width: 16px;
        height: 16px;
      }
      span {
        font-size: 12px;
        color: #23272f50;
        font-weight: 600;
      }
    }
    .editIcon {
      width: 26px;
      height: 26px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #e7effd50;
      padding: 5px;
      transition: 0.3s ease;
      cursor: pointer;
      img {
        width: 16px;
        height: 16px;
        object-fit: cover;
      }
      &:hover {
        background-color: #e7effd;
      }
    }
  }
  .cardBody {
    display: flex;
    flex-direction: column;
    gap: 8px;
    .contact {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-weight: 400;
      color: #23272f;
      padding-right: 38px;
      padding-left: 30px;
      gap: 8px;
      a {
        transition: 0.3s ease;
        &:hover {
          color: #115eed;
        }
      }
      &.list {
        flex-direction: column;
        align-items: flex-start;
        gap: 4px;
      }
      .badge {
        background-color: #115eed06;
        color: #115eed;
        font-size: 12px;
        font-weight: 600;
        padding: 4px 12px;
        border-radius: 20px;
        &.verify {
          background-color: #ffa700;
          color: #fff;
          font-size: 10px;
          padding: 4px 6px;
        }
        &.is_verified {
          background-color: #12db63;
        }
      }
    }
  }
}

@media (max-width: 425px) {
  .contactItem {
    .cardBody {
      .contact {
        font-size: 14px;
        padding-right: 0;
        padding-left: 0 !important;
        a {
          white-space: nowrap !important;
          overflow: hidden !important;
          text-overflow: ellipsis !important;
        }
        .badge {
          background-color: none;
          color: #115eed;
          font-size: 10px;
          font-weight: 500;
          padding: 0;
          &.verify {
            padding: 0 4px;
          }
        }
      }
    }
  }
}
