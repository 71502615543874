.addNewBoxModal {
  width: 100%;
  height: 100vh;
  display: none;
  justify-content: center;
  align-items: flex-start;
  padding-top: 56px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  &.active {
    display: flex;
    .modalBox {
      transform: translateY(0);
      opacity: 1;
      visibility: visible;
    }
    .modalOverlay {
      opacity: 1;
      visibility: visible;
    }
  }
  .modalOverlay {
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(35, 31, 32, 0.75);
    z-index: 999;
    opacity: 0;
    visibility: hidden;
    transition: 0.3s ease;
  }
  .modalBox {
    position: relative;
    width: 389px;
    background: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 24px;
    border-radius: 8px;
    z-index: 1000;
    transition: 0.3s ease;
    opacity: 0;
    visibility: hidden;
    .seperator {
      display: none;
      width: 56px;
      height: 4px;
      background: #c3c5d4;
      border-radius: 7px;
      margin: 0 auto 16px auto;
    }
    .modalHead {
      .modalTitle {
        // font-weight: 500;
        // font-size: 16px;
        // color: #23272f;
        // margin-bottom: 12px;
        margin-bottom: 24px;
        font-size: 24px;
        font-weight: 500;
        line-height: 24px;
      }
      .close {
        display: none;
        width: 24px;
        height: 24px;
        background: #f6f5f8;
        border-radius: 50%;
        cursor: pointer;
        transition: 0.3s ease;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        &:hover {
          opacity: 0.8;
        }
      }
    }
    .emojiList {
      margin-bottom: 16px;
      display: flex;
      gap: 12px;
      overflow-x: auto;
      transition: 0.3s ease;
      @media (max-width: 468px) {
        &::-webkit-scrollbar {
          display: none;
        }
      }
      .emojiItem {
        margin-bottom: 10px;
        padding: 4px 14px;
        width: 56px;
        height: 36px;
        background: #f6f5f8;
        border-radius: 20px;
        font-size: 18px;
        cursor: pointer;
        transition: 0.3s ease;
        &.isActive {
          background: #115eed;
        }
        &:hover {
          background: #115eed;
        }
      }
    }
    .modalForm {
      width: 100%;
      display: flex;
      flex-direction: column;
      .modalInput {
        outline: 0;
        padding: 14px 24px;
        border: 1px solid #115eed;
        border-radius: 4px;
      }
      .errorMessage {
        font-size: 14px;
        color: #ed4611;
      }
      .addBox {
        margin-top: 24px;
        border: 0;
        outline: 0;
        padding: 16px 83px;
        background: #115eed;
        border-radius: 4px;
        color: #fff;
        font-weight: 500;
        text-align: center;
        cursor: pointer;
        transition: 0.3s ease;
        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
  .closeModal {
    margin-top: 8px;
    margin-left: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 4px 12px 4px 4px;
    background: #f6f5f8;
    border-radius: 16px;
    cursor: pointer;
    transition: 0.3s ease;
    z-index: 1000;
    &:hover {
      opacity: 0.8;
    }
  }
}

@media (max-width: 768px) {
  .addNewBoxModal {
    height: auto;
    display: block;
    padding-top: 0;
    position: absolute;
    .modalBox {
      width: 100%;
      position: fixed;
      bottom: 0;
      border-radius: 20px 20px 0 0;
      transform: translateY(100%);
      .seperator {
        display: block;
      }
      .modalHead {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-bottom: 14px;
        .modalTitle {
          width: 100%;
          font-size: 24px;
          font-weight: 500;
          line-height: 24px;
          // font-size: 18px;
          color: #000000;
          text-align: center;
        }
        .close {
          display: block;
        }
      }
      .modalForm {
        .modalInput {
          margin: 24px 0 14px 0;
        }
        .addBox {
          width: 100%;
          background: #3e3ef4;
          border-radius: 120px;
          margin-bottom: 16px;
          padding: 16px 10px;
        }
      }
    }
    .closeModal {
      display: none;
    }
  }
}
