.searchToBoxModal {
  width: 100%;
  height: 100vh;
  display: none;
  justify-content: center;
  align-items: flex-start;
  padding-top: 56px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  &.active {
    display: flex;
  }
  .modalOverlays {
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(35, 31, 32, 0.75);
    z-index: 999;
  }
  .modalBox {
    position: relative;
    width: 389px;
    background: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 24px;
    border-radius: 8px;
    z-index: 1000;
    .seperator {
      display: none;
      width: 56px;
      height: 4px;
      background: #c3c5d4;
      border-radius: 7px;
      margin: 0 auto 16px auto;
    }
    .selectedUser {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 12px;
      .addUserCard {
        width: 100%;
        background: #ffffff;
        border: 1px solid #1155ed;
        border-radius: 4px;
        padding: 8px 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        transition: 0.3s ease;
        &.selected {
          border-color: #1155ed;
        }
        .left {
          display: flex;
          align-items: center;
          gap: 12px;
          .avatar {
            width: 56px;
            height: 56px;
            min-width: 56px;
            min-height: 56px;
            border-radius: 100%;
            position: relative;
            &.isDefault {
              display: flex;
              justify-content: center;
              align-items: center;
              img {
                border-radius: 0;
              }
            }
            .defaultIcon {
              width: 24px;
              height: 24px;
              object-fit: contain;
            }
            &.avatarPro {
              border: 2px solid #a528ff;
            }
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              object-position: top center;
              border-radius: 100%;
            }
            .badge {
              position: absolute;
              bottom: 0;
              right: 0;
              padding: 4px 10px;
              border-radius: 8px;
              background: linear-gradient(
                91.74deg,
                #a528ff 12.93%,
                #1d42ce 101.26%
              );
              color: #ffffff;
              font-size: 11px;
              font-weight: 500;
              &.defaultBadgePosition {
                padding: 2px 4px;
              }
            }
          }
          // .profileImg {
          //   width: 32px;
          //   height: 32px;
          //   border-radius: 50%;
          //   background-color: #1155ed;
          //   img {
          //     width: 100%;
          //     height: 100%;
          //     object-fit: cover;
          //     border-radius: 50%;
          //   }
          // }
          .userInfo {
            color: #23272f;
            height: auto !important;
            .fullName {
              color: #23272f;
              font-weight: 500;
            }
            .phone {
              font-size: 14px;
              transition: 0.3s ease;
              &:hover {
                color: #1155ed;
              }
            }
          }
        }
      }
    }
    .modalTitle {
      font-weight: 500;
      color: #23272f;
      margin: 24px 0 12px 0;
    }
    .createBoxBtn {
      padding: 8px 16px;
      border: 1px dashed #115eed;
      border-radius: 12px;
      display: flex;
      align-items: center;
      gap: 16px;
      cursor: pointer;
      transition: 0.3s ease;
      margin-bottom: 16px;
      &:hover {
        border-style: solid;
      }
      .addIcon {
        padding: 8px;
        width: 36px;
        height: 36px;
        background: #f6f5f8;
        border-radius: 20px;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
      .text {
        color: #115eed;
      }
    }
    .boxList {
      display: flex;
      flex-direction: column;
      gap: 8px;
      max-height: 400px;
      overflow-y: auto;
      .boxCard {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 16px;
        padding: 8px 16px;
        border: 1px solid #cbddeb;
        border-radius: 12px;
        transition: 0.3s ease;
        cursor: pointer;
        &:hover {
          background-color: #1155ed;
          .boxName {
            color: #fff;
          }
        }
        &.selected {
          background-color: #1155ed;
          .boxName {
            color: #fff;
          }
        }
        .boxImg {
          background: #f6f5f8;
          border-radius: 20px;
          width: 36px;
          height: 36px;
          padding: 8px;
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
        .boxName {
          color: #6c7c8f;
        }
      }
    }
    .errorMsg {
      font-size: 14px;
      color: #ed4611;
      text-align: center;
    }
    .addBtn {
      border: 0;
      outline: 0;
      padding: 16px 83px;
      background: #115eed;
      border-radius: 4px;
      font-weight: 500;
      color: #ffffff;
      margin-top: 12px;
      transition: 0.3s ease;
      border: 1px solid transparent;
      cursor: pointer;
      &:hover {
        border-color: #1155ed;
        background-color: #fff;
        color: #1155ed;
      }
    }
  }
  .closeModal {
    margin-top: 8px;
    margin-left: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 4px 12px 4px 4px;
    background: #f6f5f8;
    border-radius: 16px;
    cursor: pointer;
    transition: 0.3s ease;
    z-index: 1000;
    &:hover {
      opacity: 0.8;
    }
  }
}

@media (max-width: 768px) {
  .searchToBoxModal {
    display: none;
    padding-top: 0;
    position: fixed;
    z-index: 1000;
    .addNewBoxModal {
      bottom: 0;
      z-index: 998;
      &.active {
        z-index: 1000;
      }
    }
    &.active {
      display: flex;
    }
    .modalBox {
      position: fixed;
      width: 100%;
      padding: 24px;
      border-radius: 20px 20px 0 0;
      z-index: 1000;
      bottom: 0;
      max-height: 70%;
      .seperator {
        display: block;
      }
      .modalTitle {
        font-weight: 500;
        color: #23272f;
        margin: 18px 0 12px 0;
      }
      .createBoxBtn {
        .text {
          font-size: 14px;
        }
      }
      .boxList {
        overflow: auto;
        &::-webkit-scrollbar {
          display: none;
        }
      }
      .errorMsg {
        font-size: 14px;
        color: #ed4611;
        text-align: center;
      }
      .addBtn {
        width: 100%;
        padding: 16px 10px;
        background: #3e3ef4;
        border-radius: 120px;
      }
    }
    .closeModal {
      display: none;
    }
  }
}
