.upgradePackInfo {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .leftSide {
    padding-left: 34px;
    width: 40.8%;
    .infoList {
      .infoItem {
        position: relative;
        margin-bottom: 24px;
        .title {
          font-size: 18px;
          font-weight: 400;
          line-height: 23px;
          color: #231f20;
          margin-top: 8px;
        }
        &::after {
          content: "";
          display: inline-block;
          width: 24px;
          height: 24px;
          background-image: url(../../images/icons/check.svg);
          position: absolute;
          top: 0;
          left: -35px;
        }
      }
    }
  }
  .rightSide {
    width: 50.1%;
    height: 350px;
    border-radius: 12px;
    overflow: hidden;
    background-color: #115eed;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

/* ------------------------------- Responsive ------------------------------- */
@media (max-width: 1024px) {
  .upgradePackInfo {
    flex-direction: column-reverse ;
    gap: 16px;
    .leftSide {
      padding-left: 34px;
      width: 100%;
    }
    .rightSide {
      width: 100%;
      height: 290px;
    }
  }
}
@media (max-width: 768px) {
  .upgradePackInfo {
    .leftSide {
      padding-left: 30px;
      .infoList {
        .infoItem {
          margin-bottom: 20px;
          .title {
            font-size: 16px;
            margin-top: 6px;
          }
          &::after {
            width: 20px;
            height: 20px;
            background-size: cover;
            top: 1;
          }
        }
      }
    }
  }
}
