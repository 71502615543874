.upgradePacketArea {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 1%;
  row-gap: 16px;
  margin: 20px 0;
  .packetCard {
    border-radius: 8px;
    width: 100%;
    padding: 32px;
    position: relative;
    &.exclusive {
      background: radial-gradient(
        75.17% 158.69% at 19.64% 24.83%,
        #4380f1 0%,
        #115eed 100%
      );
      .profilArea {
        .profil {
          .bottom {
            .addition {
              padding-bottom: 0px;
            }
          }
        }
      }
    }
    &:first-child {
      background: radial-gradient(
        75.17% 158.69% at 19.64% 24.83%,
        #4380f1 0%,
        #115eed 100%
      );
      .profilArea {
        .profil {
          .bottom {
            .addition {
              padding-bottom: 0px;
            }
          }
        }
      }
    }
    // &:last-child {
    //   background: radial-gradient(
    //     83.83% 161.61% at 13.47% 16.17%,
    //     #3c3c3c 0%,
    //     #231f20 100%
    //   );
    // }
    .profilArea {
      // max-width: 342px;
      width: 100%;
      text-align: left;
      z-index: 2;
      .profil {
        padding: 16px;
        background-color: #fff;
        border-radius: 8px;
        position: relative;
        .top {
          gap: 16px;
          display: flex;
          align-items: center;
          .profilImg {
            width: 56px;
            aspect-ratio: 1/1;
            border-radius: 100%;
            overflow: hidden;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
          .destriptionImg {
            // max-width: 239px;
            width: 100%;
            height: 56px;
            border-radius: 4px;
            overflow: hidden;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
        }
        .bottom {
          color: #23272f;
          .about {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .profilName {
              font-size: 16px;
              font-weight: 500;
              line-height: 16px;
              color: inherit;
              margin: 16px 0 12px;
            }
            .addition {
              padding: 0;
              span {
                font-weight: 500;
                color: inherit;
              }
            }
          }
          .tel {
            font-size: 16px;
            font-weight: 400;
            line-height: 16px;
            color: inherit;
            margin-bottom: 12px;
          }
          .addition {
            font-size: 12px;
            font-weight: 400;
            line-height: 12px;
            color: inherit;
            padding-bottom: 8px;
          }
        }
        .threeDot {
          position: absolute;
          bottom: 16px;
          right: 16px;
        }
      }
      .profilTypeName {
        padding-top: 32px;
        font-size: 30px;
        font-weight: 400;
        line-height: 30px;
        color: #fff;
      }
    }
    .overlayBg {
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      img {
        height: 100%;
      }
    }
  }
  .freeTrialCard {
    width: 48%;
  }
}

@media (max-width: 1024px) {
  .upgradePacketArea {
    flex-direction: column;
    .packetCard {
      width: 100%;
    }
    .freeTrialCard {
      width: 100%;
    }
  }
}

@media (max-width: 768px) {
  .upgradePacketArea {
    align-items: center;
    .packetCard {
      padding: 15px;
      width: 90%;
      .profilArea {
        .profil {
          padding: 10px;

          .top {
            gap: 10px;
          }
        }
        .profilTypeName {
          padding-top: 20px;
          font-size: 25px;
        }
      }
    }
    .freeTrialCard {
      padding: 15px;
      width: 90%;
    }
  }
}
@media (max-width: 380px) {
  .upgradePacketArea {
    .packetCard {
      width: 100%;
    }
    .freeTrialCard {
      width: 100%;
    }
  }
}
