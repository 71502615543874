.forgotPassword {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  z-index: 9999;
  .screen {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    .leftSide {
      width: 50%;
      padding: 70px 120px;
      overflow: scroll;
      &::-webkit-scrollbar {
        display: none;
      }
      .formHeader {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .navigate {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 8px;
          cursor: pointer;
          transition: 0.3s ease;
          &:hover {
            opacity: 0.8;
          }
        }
      }
      .formSteps {
        padding: 70px 0;
        max-width: 390px;
      }
    }
    .rightSide {
      width: 50%;
      height: 100%;
      background-repeat: no-repeat;
      background-size: 90%;
      background-position: center center;
      background-color: #115eed;
      background-image: url(../../images/step3-bg.png);
    }
  }
}

@media (max-width: 1024px) {
  .forgotPassword {
    .screen {
      .leftSide {
        padding: 44px;
        .formSteps {
          padding: 70px 0;
          max-width: 100%;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .forgotPassword {
    .screen {
      .leftSide {
        width: 100%;
      }
      .rightSide {
        display: none;
      }
    }
  }
}

@media (max-width: 368px) {
  .forgotPassword {
    .screen {
      .leftSide {
        padding: 44px 15px;
        width: 100%;
      }
      .rightSide {
        display: none;
      }
    }
  }
}
