.choosePack {
  .container {
    padding: 0 100px;
    .sectionHead {
      width: 100%;
      padding: 35px 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .sectionBody {
      width: 100%;
      padding-top: 26px;
      .packetsHead {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        .sectionInfo {
          .packetsTitle {
            font-weight: 700;
            font-size: 30px;
            line-height: 1.35;
            color: #23272f;
          }
          .packetsInfo {
            color: #535861;
          }
        }
        .btnGroup {
          display: flex;
          justify-content: flex-end;
          width: 50%;
          margin-bottom: 0;
        }
      }
      .selectPacket {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-wrap: wrap;
        gap: 16px;
        padding: 31px 0;
        .businessBg {
          width: 32%;
          height: 467px;
          background-image: url(../../images/packet-bg.png);
          background-size: cover;
          background-position: center center;
          background-repeat: no-repeat;
        }
        .packetCard {
          width: 32%;
        }
        .freeTrialCard {
          width: 32%;
        }
      }
    }
  }
}

// Screen width 1024px
@media (max-width: 1024px) {
  .choosePack {
    .container {
      padding: 0;
      .sectionBody {
        padding: 0;
        .packetsHead {
          align-items: center;
          flex-direction: column;
          gap: 24px;
          .sectionInfo {
            .packetsTitle {
              font-size: 24px;
              margin-bottom: 16px;
            }
          }
          .btnGroup {
            width: 100%;
            margin-bottom: 36px;
          }
        }
        .selectPacket {
          padding: 0;
          .businessBg {
            display: none;
          }
          .packetCard {
            width: 48%;
          }
          .freeTrialCard {
            width: 48%;
          }
        }
      }
    }
  }
}

// Screen width 768px
@media (max-width: 768px) {
  .choosePack {
    .container {
      padding: 0;
      .sectionBody {
        padding: 0;
        .packetsHead {
          align-items: center;
          flex-direction: column;
          gap: 24px;
          .sectionInfo {
            .packetsTitle {
              font-size: 24px;
              margin-bottom: 16px;
            }
          }
          .btnGroup {
            width: 100%;
            margin-bottom: 36px;
          }
        }
        .selectPacket {
          padding: 0;
          .businessBg {
            display: none;
          }
          .packetCard {
            width: 100%;
          }
          .freeTrialCard {
            width: 100%;
          }
        }
      }
    }
  }
}

@media (min-width: 1800px) {
  .choosePack {
    padding-top: 96px;
    .container {
      padding: 0 56px;
      .sectionHead {
        display: none;
      }
      .sectionBody {
        .packetsHead {
          .sectionInfo {
            .packetsTitle {
              font-size: 40px;
              margin-bottom: 20px;
            }
            .packetsInfo {
              font-size: 24px;
            }
          }
        }
        .selectPacket {
          justify-content: space-between;
        }
      }
    }
  }
}
