.banner {
  width: 100%;
  .container {
    position: relative;
    padding: 48px;
    background-color: #115eed;
    border-radius: 12px;
    .bannerInfo {
      max-width: 552px;
      width: 100%;

      .bannerCaption {
        font-size: 30px;
        font-weight: 700;
        line-height: 41px;
        color: #ffffff;
      }
      .loqinBtn {
        background-color: #fff;
        border-radius: 4px;
        font-family: Poppins;
        padding: 16px;
        max-width: 241px;
        width: 100%;
        height: 48px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        outline: none;
        margin-top: 24px;
        font-size: 16px;
        font-weight: 400;
        line-height: 16px;
        color: #115eed;
      }
    }
    .pattern {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      right: 0;
      background-image: url(../../../images/icons/banner-pattern-desktop.svg);
      background-position: right;
      background-size: cover;
    }
  }
}
@media (max-width: 768px) {
  .banner {
    width: 100%;
    .container {
      padding: 30px;
      .bannerInfo {
        max-width: 300px;
        width: 100%;

        .bannerCaption {
          font-size: 24px;
        }
        .loqinBtn {
          padding: 14px;
          max-width: 241px;
          height: 44px;
          margin-top: 20px;
          font-size: 14px;
        }
      }
      .pattern {
        background-image: url(../../../images/icons/banner-pattern-mobile.svg);
      }
    }
  }
}
@media (max-width: 428px) {
  .banner {
    .container {
      padding: 25px;
      .bannerInfo {
        max-width: 100%;
        .bannerCaption {
          font-size: 20px;
        }
      }
    }
  }
}
