.dashboardPages {
  width: 100%;
  padding: 110px 32px 32px 336px;
}

/* ------------------------------- Responsive ------------------------------- */

@media (max-width: 768px) {
  .dashboardPages {
    padding: 30px 16px 90px;
  }
}
