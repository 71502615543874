.creditCard {
  width: 100%;
  padding: 36px 0;
  display: flex;
  gap: 32px;
  border-bottom: 1px solid #cbddeb;
  .icon {
    width: 103px;
    height: 70px;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  .cardInfo {
    display: flex;
    flex-direction: column;
    gap: 16px;
    .info {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      width: 100%;
      p {
        font-size: 14px;
        display: flex;
      }
      .title {
        width: 30%;
        color: #535861b2;
      }
      .data {
        width: 70%;
        color: #535861;
      }
    }
    .buttonArea {
      .btn {
        border: 0;
        outline: 0;
        background: none;
        font-size: 14px;
        color: #115eed;
        cursor: pointer;
        transition: 0.3s ease;
        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .creditCard {
    &:first-child {
      padding-top: 0;
    }
    .icon {
      margin-top: -18px;
    }
    .cardInfo {
      .info {
        flex-direction: column;
        gap: 12px;
        .title {
          width: 100%;
        }
        .data {
          width: 100%;
        }
      }
    }
  }
}
