.settingsPage {
  width: 100%;
  // background: #fbfbfb;
  padding: 99px 0;
  position: relative;
  // height: 100vh;
  .settingsMobileHeader {
    width: 100%;
    padding: 16px;
    padding-top: 0;
    display: none;
    align-items: center;
    img {
      width: 24px;
      height: 24px;
      object-fit: contain;
    }
    p {
      width: calc(100% - 56px);
      text-align: center;
      font-size: 20px;
      color: #000000;
    }
  }
  .row {
    width: 100%;
    padding: 0 18px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 25px;
    .leftSide {
      width: 33%;
    }
    .rightSide {
      width: 66%;
    }
  }
}

@media (max-width: 768px) {
  .settingsPage {
    width: 100%;
    padding: 100px 0;
    position: relative;
    .settingsMobileHeader {
      display: flex;
    }
    .row {
      flex-direction: column;
      .overlaySide {
        width: 100%;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.5);
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: none;
        z-index: 1000;
        &.isActive {
          display: block;
        }
      }
      .leftSide {
        width: 100%;
        height: calc(100vh - 140px);
        background-color: #fff;
        position: absolute;
        top: 140px;
        left: 0;
        bottom: 0;
        right: 0;
        padding: 18px;
        transform: translateX(-100%);
        transition: 0.3s ease;
        opacity: 0;
        visibility: hidden;
        overflow-y: auto;
        z-index: 1001;
        &.isOpen {
          transform: translateX(0);
          opacity: 1;
          visibility: visible;
        }
      }
      .rightSide {
        width: 100%;
        margin-bottom: 100px;
      }
    }
  }
}
