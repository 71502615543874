.upgradePackNumber {
  padding: 156px 90px;
  .number {
    display: inline-block;
    font-size: 120px;
    font-weight: 400;
    line-height: 120px;
    letter-spacing: 0em;
    text-align: left;
    background: linear-gradient(90deg, #115eed 0%, #ff00a8 100%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .text {
    padding-top: 2px;
    line-height: 24px;
    color: #535861;
  }
}

/* ------------------------------- Responsive ------------------------------- */
@media (max-width: 1024px) {
  .upgradePackNumber {
    padding: 120px 0;
    text-align: center;
    .number {
      font-size: 100px;
    }
  }
}
@media (max-width: 768px) {
  .upgradePackNumber {
    padding: 100px 0;
    text-align: center;
    .number {
      font-size: 80px;
    }
  }
}
@media (max-width: 380px) {
  .upgradePackNumber {
    padding: 80px 0;
    text-align: center;
    .number {
      font-size: 50px;
    }
  }
}
