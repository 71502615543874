.qrCodeScanner {
  padding-top: 90px;
  #reader {
    width: 100%;
    #reader__scan_region {
      width: 100%;
    }
    #reader__dashboard {
      #reader__dashboard_section {
        width: 100%;
      }
    }
  }
}
