.createProfile {
  width: 100%;
  .experienceComp {
    display: flex;
    flex-direction: column;
    gap: 10px;
    .stillWorking {
      display: flex;
      gap: 5px;
    }
    .experienceInp {
      display: flex;
      gap: 10px;
      width: 100%;
      .customInput {
        width: 50%;
        label {
          font-size: 14px;
          color: #535861;
          font-weight: 400;
        }
      }
    }
    .experienceInp2 {
      display: flex;
      gap: 10px;
      width: 100%;
      .selectInp {
        display: flex;
        flex-direction: column;
        width: 50%;
        gap: 10px;
        select {
          padding: 18px 24px;
          border: 1px solid rgba(201, 202, 205, 0.3137254902);
          outline: none;
        }
        label {
          font-size: 14px;
          color: #535861;
          font-weight: 400;
        }
      }
      .customInput {
        width: 49%;
        label {
          font-size: 14px;
          color: #535861;
          font-weight: 400;
        }
      }
      .timeBox {
        display: flex;
        width: 49%;
        gap: 10px;
        .customInput {
          input {
            padding: 17px 4px;
            border-radius: 0;
            font-size: 14px;
          }
        }
      }
    }
  }
  .btnGroup {
    display: flex;
    align-items: center;
    gap: 12px;
    justify-content: flex-end;
    margin-top: 10px;
    .btn {
      width: 30%;
      border: 0;
      outline: 0;
      border: 1px solid transparent;
      padding: 12px;
      border-radius: 4px;
      font-size: 14px;
      font-weight: 400;
      background-color: #115eed;
      color: #ffffff;
      cursor: pointer;
      transition: 0.3s ease;
      &:hover {
        border-color: #115eed50;
      }
      &.cancel {
        background-color: #115eed03;
        color: #000000;
      }
    }
  }
}

@media (max-width: 726px) {
  .createProfile {
    .experienceComp {
      .experienceInp {
        flex-direction: column;
        .customInput {
          width: 100%;
        }
      }
      .experienceInp2 {
        flex-direction: column;
        .selectInp {
          width: 100%;
        }
        .customInput {
          width: 100%;
        }
        .timeBox {
          width: 100%;
        }
      }
    }
  }
}

@media (max-width: 400px) {
  .createProfile {
    .experienceComp {
      .experienceInp2 {
        .timeBox {
          flex-direction: column;
        }
      }
    }
  }
  .btnGroup {
    flex-direction: column;
    .btn {
      width: 100% !important;
    }
  }
}
