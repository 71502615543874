.profileHero {
  width: 100%;
  .row {
    padding: 0;
    gap: 16px;
    .overlay {
      display: none;
      width: 100%;
      height: 100vh;
      opacity: 0.7;
      background: transparent;
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1;
      &.active {
        display: block;
      }
    }
    .topSide {
      width: 100%;
      max-width: 619px;
      .mobileTopBar {
        width: 100%;
        display: none;
        justify-content: space-between;
        align-items: center;
        padding: 8px 0;
        margin-bottom: 8px;
        .username {
          font-size: 20px;
          font-weight: 400;
          color: #000000;
        }
        .account {
          display: none;
          cursor: pointer;
          position: relative;
          .title {
            img {
              transition: transform 0.3s ease;
              transform: rotate(0);
            }
            &.up {
              img {
                transform: rotate(180deg);
              }
            }
          }
          .accountMenu {
            display: flex;
            opacity: 0;
            visibility: hidden;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            gap: 4px;
            position: absolute;
            top: 100%;
            right: 0;
            padding: 8px;
            min-width: 304px;
            background: #ffffff;
            box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.1);
            border-radius: 12px;
            transition: display 0.3s ease;
            transition: opacity 0.3s ease;
            z-index: 1000;
            .accountMenuItem {
              width: 100%;
              a {
                width: 100%;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap: 10px;
                padding: 10px 12px;
                color: #000;
                background: #fff;
                transition: 0.3s ease;
                border-radius: 8px;
                img {
                  width: 28px;
                  height: 28px;
                  border-radius: 50%;
                  object-fit: cover;
                }
                &:hover {
                  background: #115eed;
                  color: #fff;
                }
              }
              &.goPro {
                a {
                  background: #115eed;
                  color: #fff;
                  &:hover {
                    img {
                      filter: invert(0%);
                    }
                  }
                }
              }
            }
            &.open {
              opacity: 1;
              visibility: visible;
            }
          }
        }
      }
      .images {
        display: flex;
        gap: 16px;
        .avatar {
          width: 120px;
          height: 120px;
          min-width: 120px;
          min-height: 120px;
          border-radius: 50%;
          position: relative;
          border: 1px solid transparent;
          .editIcon {
            position: absolute;
            top: -10px;
            right: -10px;
            width: 26px;
            height: 26px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: rgba(231, 239, 253, 0.3137254902);
            padding: 5px;
            transition: 0.3s ease;
            cursor: pointer;
            z-index: 1;
            img {
              width: 16px;
              height: 16px;
              object-fit: cover;
            }
          }
          img {
            border-radius: 50%;
          }
          .badge {
            position: absolute;
            bottom: 0;
            right: 0;
            padding: 7px 15px;
            border-radius: 8px;
            background: linear-gradient(
              91.74deg,
              #a528ff 12.93%,
              #1d42ce 101.26%
            );
            color: #ffffff;
            font-size: 11px;
            font-weight: 500;
          }
        }
        .avatarPro {
          // &::after {
          //   position: absolute;
          //   top: 0;
          //   left: 0;
          //   display: inline-block;
          //   content: "";
          //   width: 120px;
          //   height: 120px;
          //   padding: 1px;
          //   background: linear-gradient(
          //     91.74deg,
          //     #a528ff 12.93%,
          //     #1d42ce 101.26%
          //   );
          //   border-radius: 50%;
          // }
          border: 2px solid #a528ff;
          img {
            position: absolute;
            // transform: translateX(2.5px) translateY(2.5px);
            border-radius: 100%;
            z-index: 1;
          }
          .badge {
            z-index: 1;
          }
        }
        .background {
          width: 100%;
          max-width: 483px;
          height: 120px;
          border-radius: 6px;
          position: relative;
          .editIcon {
            position: absolute;
            top: 0;
            right: 0;
            width: 26px;
            height: 26px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: rgba(231, 239, 253, 0.3137254902);
            padding: 5px;
            transition: 0.3s ease;
            cursor: pointer;
            z-index: 1;
            img {
              width: 16px;
              height: 16px;
              object-fit: cover;
            }
          }
          img {
            border-radius: 6px;
          }
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center center;
        }
      }
    }
    .bottomSide {
      flex-direction: column;
      .profileContact {
        width: 100%;
        // max-width: 439px;
        min-width: 439px;
        background-color: #ffffff;
        border: 1px solid #cbddeb50;
        padding: 24px;
        border-radius: 8px;
        .title {
          font-size: 20px;
          color: #23272f;
          font-weight: 500;
          margin-bottom: 24px;
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .profileHero {
    .row {
      padding: 0 56px !important;
      gap: 16px;
      .topSide {
        max-width: 100%;
        .images {
          gap: 16px;
          .avatar {
            width: 120px;
            height: 120px;
            min-width: 120px;
            min-height: 120px;
          }
          .avatarPro {
            &::after {
              width: 120px;
              height: 120px;
            }
          }
          .background {
            max-width: 100%;
          }
        }
      }
      .bottomSide {
        .profileContact {
          max-width: 100%;
          min-width: auto;
          padding: 24px;
        }
        .videoPlayer {
          margin-top: 15px;
          max-width: 100%;
          max-height: 248px;
          .editIcon {
            & + div {
              width: 100% !important;
            }
          }
          div {
            width: 100%;
            max-width: 100%;
            max-height: 467px;
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .profileHero {
    .row {
      padding: 0 10px !important;
      gap: 16px;
      .topSide {
        max-width: 100%;
        .mobileTopBar {
          display: flex;
          .account {
            display: flex;
            position: absolute;
            top: 40px;
            right: 10px;
          }
        }
        .images {
          gap: 16px;
          .avatar {
            width: 83px;
            height: 83px;
            min-width: 83px;
            min-height: 83px;
          }
          .avatarPro {
            &::after {
              width: 83px;
              height: 83px;
            }
          }
          .background {
            max-width: 100%;
            height: 83px;
          }
        }
      }
      .bottomSide {
        .profileContact {
          max-width: 100%;
          padding: 12px 6px;
        }
        .videoPlayer {
          margin-top: 15px;
          max-width: 100%;
          max-height: 248px;
          .editIcon {
            & + div {
              width: 100% !important;
            }
          }
          div {
            max-width: 100%;
            max-height: 248px;
          }
        }
      }
    }
  }
}

@media (max-width: 425px) {
  .profileHero {
    .row {
      padding: 0 10px !important;
      .topSide {
        .mobileTopBar {
          .account {
            display: flex;
            position: absolute;
            top: 40px;
            right: 10px;
          }
        }
        .images {
          gap: 16px;
          .avatar {
            width: 83px;
            height: 83px;
            min-width: 83px;
            min-height: 83px;
          }
          .avatarPro {
            &::after {
              width: 83px;
              height: 83px;
            }
            .badge {
              padding: 2px 10.5px;
              font-size: 11px;
            }
          }
        }
      }
    }
  }
}
