.sharedProfileModal {
  width: 100%;
  height: 100vh;
  display: none;
  justify-content: center;
  align-items: center;
  padding-top: 56px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  &.active {
    display: flex;
  }
  .modalOverlay {
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(35, 31, 32, 0.75);
    z-index: 999;
  }
  .modalBox {
    position: relative;
    width: 389px;
    background: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    padding: 16px 12px 32px 12px;
    border-radius: 8px;
    z-index: 1000;
    color: #23272f;
    font-weight: 500;
    .seperator {
      display: none;
      width: 56px;
      height: 4px;
      background: #c3c5d4;
      border-radius: 7px;
      margin: 0 auto 16px auto;
    }
    .addProfileHead {
      .close {
        display: none;
        width: 24px;
        height: 24px;
        cursor: pointer;
        transition: 0.3s ease;
        &:hover {
          opacity: 0.8;
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
    .profileInfo {
      width: 100%;
      border-radius: 4px;
      border: 1px solid rgba(17, 94, 237, 0.25);
      background: #fff;
      padding: 16px;
      .cardTop {
        width: 100%;
        height: 56px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 16px;
        margin-bottom: 16px;
        .profileImg {
          width: 56px;
          height: 56px;
          background: #f1f5fe;
          border-radius: 50%;
          position: relative;
          &.goldBrd {
            outline: 3px solid #a528ff;
            .badge {
              position: absolute;
              bottom: 0;
              right: 0;
              padding: 2px 8px;
              border-radius: 8px;
              background: linear-gradient(
                91.74deg,
                #a528ff 12.93%,
                #1d42ce 101.26%
              );
              color: #ffffff;
              font-size: 11px;
              font-weight: 500;
            }
          }
          img {
            width: 56px;
            height: 56px;
            border-radius: 50%;
            object-fit: cover;
          }
        }
        .profileBanner {
          width: 285px;
          height: 56px;
          background: #115eed;
          border-radius: 4px;
          img {
            width: 100%;
            height: 100%;
            border-radius: 4px;
            object-fit: cover;
          }
        }
      }
      .cardBottom {
        display: flex;
        flex-direction: column;
        gap: 4px;
        .cardTitle {
          display: flex;
          justify-content: space-between;
          align-items: center;
          color: #23272f;
          font-weight: 500;
          .title {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: flex-start;
            flex-direction: row;
            gap: 4px;
            .workInfo{
              font-size: 14px;
            }
            .fullName{
              font-weight: 700;
              font-size: 18px;
            }
            .badge {
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 2px 8px;
              background: #ffa700;
              border: 1px solid #ffffff;
              border-radius: 12px;
              font-weight: 500;
              font-size: 10px;
              color: #ffffff;
              text-align: center;
            }
            .inBoxCount {
              color: #115eed;
              font-size: 14px;
            }
          }
        }
        .cardInfo {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .info {
            .phone {
              transition: 0.3s ease;
              &:hover {
                color: #1155ed;
              }
            }
          }
          .cardBtn {
            border: 0;
            outline: 0;
            font-size: 14px;
            color: #115eed;
            padding: 8px 20px 8px 16px;
            background: rgba(17, 94, 237, 0.06);
            border-radius: 6px;
            transition: 0.3s ease;
            cursor: pointer;
            &:hover {
              background: #1155ed;
              color: #fff;
            }
          }
        }
      }
    }
    .qrCode {
      width: 180px;
      height: 180px;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    .shareSocial {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 12px;
      .socialButton {
        a {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 48px;
          height: 48px;
          border: 1px solid #535861;
          border-radius: 50%;
        }
        transition: 0.3s ease;
        &:hover {
          transform: scale(1.1);
        }
      }
    }
    .link {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: 6px;
      background: #f1f5f7;
      padding: 16px;
      .sharedLink {
        color: #c4c4c4;
        font-size: 14px;
        span {
          color: #231f20;
        }
      }
      .copy {
        color: #115eed;
        font-size: 12px;
        font-weight: 400;
        cursor: pointer;
        transition: 0.3s ease;
        &:hover {
          opacity: 0.8;
        }
      }
    }
    .closeModal {
      position: absolute;
      top: -8px;
      right: -110px;
      margin-top: 8px;
      margin-left: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      padding: 4px 12px 4px 4px;
      background: #f6f5f8;
      border-radius: 16px;
      cursor: pointer;
      transition: 0.3s ease;
      z-index: 1000;
      &:hover {
        opacity: 0.8;
      }
    }
  }
}

@media (max-width: 768px) {
  .sharedProfileModal {
    display: block;
    height: auto;
    padding-top: 0;
    &.active {
      .modalOverlay {
        opacity: 1;
        visibility: visible;
      }
      .modalBox {
        opacity: 1;
        visibility: visible;
        transform: translateY(0);
      }
    }
    .modalOverlay {
      opacity: 0;
      visibility: hidden;
      transition: 0.3s ease;
    }
    .modalBox {
      padding: 16px;
      position: fixed;
      bottom: 0;
      width: 100%;
      border-radius: 20px 20px 0 0;
      transform: translateY(100%);
      opacity: 0;
      visibility: hidden;
      transition: 0.3s ease;
      .seperator {
        display: block;
      }
      .addProfileHead {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        .close {
          display: block;
        }
      }
      .closeModal {
        display: none;
      }
    }
  }
}
