.profileEmailEdit {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 25px;
  .editEmailArea {
    display: flex;
    flex-wrap: wrap;
    max-height: 350px;
    overflow: auto;
    gap: 10px;
    .phoneCaption {
      background-color: #f6f5f8;
      border-radius: 20px;
      outline: none;
      border: none;
      display: flex;
      gap: 10px;
      padding: 9px 16px;
      color: #23272f;
      font-size: 14px;
      font-weight: 400;
      span {
        color: #535861;
        cursor: pointer;
        transition: 0.3s ease;
        &:hover {
          scale: 1.2;
          color: #000;
        }
      }
    }
  }
  .infoEmailForm {
    display: flex;
    gap: 23px;
    width: 100%;
    justify-content: center;
    flex-direction: column;

    .customInput {
      width: 100%;
    }
    .deleteBtn {
      background: transparent;
      border: none;
      outline: none;
      cursor: pointer;
      width: 28px;
      height: 28px;
      margin-top: 10px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  .btnGroup {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 12px;
    .addBtn {
      display: flex;
      justify-content: center;
      width: 20%;
      background-color: #e7effd;
      border: none;
      outline: none;
      gap: 6px;
      border-radius: 17px;
      padding: 8px 12px;
      font-size: 12px;
      font-weight: 400;
      color: #115eed;
      align-items: center;
      cursor: pointer;
      transition: 0.5s all ease-in-out;
      &:hover {
        background-color: #115eed50;
      }
      .btnAdd {
        background: transparent;
        border: none;
        outline: none;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .btn {
      width: 164px;
      border: 0;
      outline: 0;
      border: 1px solid transparent;
      padding: 12px;
      border-radius: 4px;
      font-size: 14px;
      font-weight: 400;
      background-color: #115eed;
      color: #ffffff;
      cursor: pointer;
      transition: 0.3s ease;
      &:hover {
        border-color: #115eed50;
      }
      &.cancel {
        background-color: #115eed03;
        color: #000000;
      }
    }
  }
}

@media (max-width: 425px) {
  .profileEmailEdit {
    .btnGroup {
      .addBtn {
        width: 100%;
      }
    }
  }
}
