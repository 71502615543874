.searchResult {
  width: 100%;
  padding: 96px 16px;
  .companies {
    width: 100%;
    .topBar {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 12px;
      .info {
        display: flex;
        justify-content: space-between;
        .boxName {
          display: flex;
          align-items: center;
          gap: 16px;
          font-weight: 500;
          font-size: 20px;
          color: #000000;
          .searchedWord {
            font-size: 24px;
            color: #000000;
          }
          .goBack {
            display: flex;
            align-items: center;
            gap: 8px;
            padding: 4px 12px 4px 4px;
            background: #f6f5f8;
            border-radius: 16px;
            cursor: pointer;
            transition: 0.3s ease;
            &:hover {
              background: #ddd;
            }
            span {
              color: #23272f50;
              font-size: 12px;
            }
          }
        }
        .searchInfo {
          display: flex;
          align-items: center;
          gap: 24px;
          .resultCount {
            color: #535861;
          }
        }
      }
      .searchInp {
        width: 100%;
        display: none;
        justify-content: flex-start;
        align-items: center;
        padding: 0 16px;
        background: #ffffff;
        border: 1px solid #115eed;
        border-radius: 22px;
        &.error {
          border-color: #ed4611;
        }
        .searchIcon {
          border: 0;
          outline: 0;
          background-color: #fff;
          width: 24px;
          height: 24px;
          margin-right: 10px;
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
        input {
          font-size: 14px;
          color: #23272f;
          border: 0;
          outline: 0;
          width: 100%;
          padding: 12px 0;
          border-radius: 5px;
        }
      }
    }
    .profiles {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      .results {
        width: 100%;
        margin-top: 16px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        gap: 16px;
        .profileCard {
          width: calc(25% - 12px);
        }
      }
      .noResult {
        width: 100%;
        padding-top: 120px;
        text-align: center;
        .info {
          font-size: 24px;
          color: #23272f;
          font-size: 700;
          span {
            color: #1155ed;
          }
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .searchResult {
    .companies {
      .profiles {
        .results {
          .profileCard {
            width: calc(33% - 12px);
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .searchResult {
    padding: 26px 16px 96px;
    .companies {
      .topBar {
        .info {
          .boxName {
            gap: 8px;
            .searchedWord {
              font-size: 14px;
            }
          }
          .searchInfo {
            gap: 8px;
            font-size: 12px;
          }
        }
        .searchInp {
          display: flex;
        }
      }
      .profiles {
        .results {
          .profileCard {
            width: calc(50% - 12px);
          }
        }
        .noResult {
          .info {
            font-size: 16px;
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .searchResult {
    .companies {
      .profiles {
        .results {
          .profileCard {
            width: 100%;
          }
        }
      }
    }
  }
}
