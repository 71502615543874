.searchPage {
  width: 100%;
  height: 100vh;
  position: relative;
  top: 0;
  left: 0;
  .outsildeClickOverlay {
    width: 100%;
    height: 100vh;
    background-color: transparent;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    display: none;
    &.active {
      display: block;
    }
  }
  .sectionHeader {
    width: 100%;
    height: 50%;
    background-image: url(../../images/search-bg.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    .topSide {
      height: 26%;
      padding: 32px 120px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .logo {
        img {
          &.mobile {
            width: 34px;
            height: 16px;
            object-fit: contain;
            display: none;
          }
        }
      }
      .account {
        width: auto !important;
        cursor: pointer;
        position: relative;
        display: flex;
        align-items: center;
        .menuBars {
          display: none;
          justify-content: center;
          align-items: center;
          width: 32px;
          height: 12px;
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
        .selectLang {
          margin-right: 16px;
          border-color: #ffffff12;
          & > div {
            color: #fff;
          }
          &:hover {
            border-color: #fff;
          }
        }
        .shareIcon {
          height: 32px;
          padding: 4px 12px;
          border-radius: 16px;
          border: 1px solid #ffffff12;
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 8px;
          cursor: pointer;
          transition: 0.3s ease;
          margin-right: 14px;
          &:hover {
            border-color: #fff;
          }
          .icon {
            width: 24px;
            height: 24px;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
          .shareInfo {
            color: #ffffff;
          }
        }
        .profileBadge {
          display: flex;
          align-items: center;
          position: relative;
          cursor: pointer;
        }
        .avatar {
          width: 32px;
          height: 32px;
          min-width: 32px;
          min-height: 32px;
          margin-right: 8px;
          border-radius: 100%;
          position: relative;
          &.isDefault {
            display: flex;
            justify-content: center;
            align-items: center;
            img {
              border-radius: 0;
            }
          }
          .defaultIcon {
            width: 24px;
            height: 24px;
            object-fit: contain;
          }
          // &.avatarPro {
          //   border: 2px solid #a528ff;
          // }
          background-color: #fff;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: top center;
            border-radius: 100%;
          }
          // .badge {
          //   position: absolute;
          //   bottom: 0;
          //   right: 0;
          //   padding: 4px 10px;
          //   border-radius: 8px;
          //   background: linear-gradient(
          //     91.74deg,
          //     #a528ff 12.93%,
          //     #1d42ce 101.26%
          //   );
          //   color: #ffffff;
          //   font-size: 11px;
          //   font-weight: 500;
          //   &.defaultBadgePosition {
          //     padding: 2px 4px;
          //   }
          // }
        }
        .title {
          color: #fff;
          font-weight: 500;
        }
        .accountMenu {
          display: flex;
          opacity: 0;
          visibility: hidden;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;
          gap: 4px;
          position: absolute;
          top: 40px;
          right: 0;
          padding: 8px;
          min-width: 304px;
          background: #ffffff;
          box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.1);
          border-radius: 12px;
          transition: 0.3s ease;
          z-index: 1000;
          .accountMenuItem {
            width: 100%;
            a {
              width: 100%;
              display: flex;
              justify-content: flex-start;
              align-items: center;
              gap: 10px;
              padding: 10px 12px;
              color: #000;
              background: #fff;
              transition: 0.3s ease;
              border-radius: 8px;
              &:hover {
                background: #115eed;
                color: #fff;
                img {
                  filter: invert(100%);
                }
                .medalIcon {
                  filter: invert(0);
                }
              }
              .medalIcon {
                width: 30px;
                height: 30px;
                border-radius: 50px;
              }
            }
          }
          &.open {
            opacity: 1;
            visibility: visible;
          }
        }
      }
    }
    .bottomSide {
      width: 100%;
      height: 74%;
      display: flex;
      justify-content: center;
      align-items: center;
      .title {
        font-weight: 700;
        font-size: 40px;
        line-height: 1.35;
        text-align: center;
        color: #f1f6ff;
      }
    }
  }
  .sectionBody {
    width: 100%;
    height: 50%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    .searchForm {
      width: 100%;
      height: 56px;
      transform: translateY(-28px);
      max-width: 794px;
      background: #ffffff;
      border: 1px solid rgba(17, 94, 237, 0.06);
      box-shadow: 0px 0px 12px rgba(17, 94, 237, 0.06);
      border-radius: 4px;
      padding: 8px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border: 1px solid transparent;
      &.isError {
        border-color: #ed4611;
      }
      label {
        width: 24px;
        height: 24px;
        cursor: pointer;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
      .searchInput {
        border: 0;
        outline: 0;
        width: 100%;
        height: 54px;
        padding: 10px;
      }
      .searchBtn {
        border: 0;
        outline: 0;
        min-width: 105px;
        padding: 12px 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #115eed;
        border-radius: 4px;
        color: #fff;
        transition: 0.3s ease;
        cursor: pointer;
        &:hover {
          opacity: 0.8;
        }
      }
    }
    .errorMessage {
      color: #ed4611;
      font-size: 14px;
      position: absolute;
      background: #fff;
      padding: 5px 10px;
      border-radius: 5px;
      transition: 0.3s ease;
      transform: translateY(-60px);
      opacity: 0;
      visibility: hidden;
      &.isError {
        opacity: 1;
        visibility: visible;
        transform: translateY(-70px);
      }
      &::after {
        content: "";
        display: inline-block;
        width: 0;
        height: 0;
        border-top: 10px solid #fff;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 0px solid transparent;
        position: absolute;
        bottom: -5px;
        left: calc(50% - 10px);
        transition: 0.3s ease;
      }
    }
    .keywordList {
      width: 100%;
      max-width: 900px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      gap: 16px;
      height: 92px;
      max-height: 92px;
      .keyword {
        padding: 12px 29px;
        background: rgba(17, 94, 237, 0.03);
        border-radius: 6px;
        color: #115eed;
        cursor: pointer;
        transition: 0.3s ease;
        text-transform: capitalize;
        &:hover {
          background: #115eed;
          color: #fff;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .searchPage {
    height: 100%;
    .sectionHeader {
      height: 270px;
      .topSide {
        height: 30%;
        padding: 12px 16px;
        align-items: center;
        .logo {
          width: 100px;
          height: auto;
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
        .account {
          .title {
            font-size: 12px;
          }
          .accountMenu {
            min-width: 230px;
          }
          .shareIcon {
            display: none;
          }
        }
      }
      .bottomSide {
        height: 50%;
        padding: 0 16px;
        .title {
          font-size: 18px;
        }
      }
    }
    .sectionBody {
      height: auto;
      .searchForm {
        max-width: 90%;
        .searchBtn {
          min-width: auto;
          padding: 6px 8px;
        }
      }
      .keywordList {
        max-width: 90%;
        gap: 8px;
        height: auto;
        max-height: auto;
        .keyword {
          padding: 6px 8px;
        }
      }
    }
  }
}

@media (max-width: 468px) {
  .searchPage {
    .sectionHeader {
      .topSide {
        .logo {
          img {
            display: none;
            &.mobile {
              display: block;
            }
          }
        }
        .account {
          .profileBadge {
            display: none;
          }
          .menuBars {
            display: flex;
          }
          .selectLang {
            display: none;
          }
          .avatar {
            display: none;
          }
          .title {
            display: none;
          }
        }
      }
    }
  }
}
