.verification {
  width: 100%;
  height: 100vh;
  padding: 0 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  gap: 24px;
  .container {
    width: 100%;
    height: 100%;
    .row {
      width: 100%;
      height: 100%;
      .verificationContainer {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 10px;
        a {
          color: #115eed;
          transition: 0.3s ease;
          &:hover {
            opacity: 0.8;
          }
        }
      }
    }
  }
}
