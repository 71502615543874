/* ------------------------------- Reset Code ------------------------------- */
.text {
  line-height: 24px;
  color: #535861;
}
.upgradePack {
  .packetArea {
    .row {
      padding: 0;
      gap: 16px;
      justify-content: center;
      .packetCard {
        height: max-content;
        margin-bottom: 20px;
        width: 100%;
        &:first-child {
          background: #ffffff;
          border: 1px solid rgba(17, 94, 237, 0.12);
          border-radius: 0px 0px 4px 4px;
          .cardTitle,
          .cardPrice {
            color: #23272f;
          }
          .perMonth,
          .packetDiscount {
            color: #535861;
          }
          .featureList {
            .listItem {
              color: #535861;
              &::after {
                background-image: url(../../../images/icons/check-solid.svg);
              }
            }
          }
        }
      }
      .freeTrialCard {
        border-radius: 0px 0px 4px 4px;
        margin-bottom: 20px;
      }
    }
  }
}

/* ------------------------------- Responsive ------------------------------- */
@media (max-width: 1024px) {
  .upgradePack {
    .packetArea {
      .row {
        flex-direction: column;
        .packetCard {
          height: max-content;
          max-width: none;
          width: 100%;
        }
        .freeTrialCard {
          height: max-content;
          max-width: none;
          width: 100%;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .upgradePack {
    .packetArea {
      .row {
        .packetCard {
          width: 100%;
        }
        .freeTrialCard {
          width: 100%;
        }
      }
    }
  }
}
