@import "../../mixins";

.profile {
  width: 100%;
  background: #ffffff;
  padding: 99px 0;
  position: relative;
  .mobileHeader {
    width: 100%;
    padding: 48px 0 10px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    a {
      padding: 9px 0;
      text-align: center;
      border-radius: 10px;
      background-color: #e9ecf3;
    }
  }
  .sideBar {
    width: 72px;
  }
  .container {
    max-width: 1072px;
    margin: 0 auto;
    .row {
      width: 100%;
      padding: 0;
      gap: 14px;
    }
    .profileBottom {
      margin-top: 16px;
      display: flex;
      flex-direction: column;
      gap: 16px;
      .doProNotification {
        width: 100%;
        max-width: 619px;
        background-color: #ffffff;
        border: 1px solid #cbddeb50;
        border-radius: 8px;
        padding: 24px 30px;
        h2 {
          font-weight: 500;
          font-size: 28px;
          color: #000000;
        }
        p {
          font-weight: 400;
          font-size: 16px;
          color: #57565b;
          margin-bottom: 34px;
        }
        .btn {
          @include primaryBtn();
        }
      }
    }
  }
}

@media (max-width: 1200px) {
  .profile {
    .container {
      margin: 0 0 0 100px;
    }
  }
}

@media (max-width: 1024px) {
  .profile {
    .container {
      max-width: 100%;
      margin: 0 0 0 auto;
      .row {
        flex-direction: column;
      }
      .profileBottom {
        margin-left: 55px;
        .doProNotification {
          max-width: 100%;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .profile {
    padding-top: 21px;
    .sideBar {
      display: none;
    }
    .container {
      margin: 0 auto;
      .profileBottom {
        padding: 0 10px;
        margin-left: 0;
        margin-top: 20px;
      }
    }
  }
}

@media (max-width: 425px) {
  .profile {
    .container {
      .profileBottom {
        padding: 0 10px;
        .doProNotification {
          padding: 24px;
        }
      }
    }
  }
}
