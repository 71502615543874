@import "../../../mixins";

.profilePhoneEdit {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 25px;
  .editPhoneArea {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    .phoneCaption {
      background-color: #f6f5f8;
      border-radius: 20px;
      outline: none;
      border: none;
      display: flex;
      gap: 10px;
      padding: 9px 16px;
      color: #23272f;
      font-size: 14px;
      font-weight: 400;
      span {
        color: #535861;
        cursor: pointer;
        transition: 0.3s ease;
        &:hover {
          scale: 1.2;
          color: #000;
        }
      }
      .verifyBtn {
        color: #115eed;
        &:hover {
          scale: 1;
          color: #115eed;
          opacity: 0.8;
        }
      }
    }
  }
  .infoPhoneForm {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 23px;
    width: 100%;
    .customInput {
      width: 100%;
    }
    .deleteBtn {
      background: transparent;
      border: none;
      outline: none;
      cursor: pointer;
      width: 28px;
      height: 28px;
      margin-top: 10px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .phoneBox {
      width: 100%;
      label {
        display: inline-block;
        font-size: 14px;
        color: #535861;
        margin-bottom: 12px;
      }
      .PhoneInput {
        .PhoneInputInput {
          @include formInput();
          font-size: 16px;
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
          &.err {
            border-color: #ed4611;
          }
        }
        .PhoneInputCountry {
          background: #f2f4f8;
          margin-right: 0;
          padding: 10px;
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
        }
      }
      .errorAlert {
        font-size: 14px;
        color: #ed4611;
        position: relative;
        padding-left: 26px;
        &::before {
          content: "";
          background-image: url(../../../../images/icons/alert.svg);
          background-repeat: no-repeat;
          background-position: center center;
          background-size: contain;
          width: 20px;
          height: 20px;
          position: absolute;
          top: 0;
          left: 0;
        }
      }
    }
  }
  .btnGroup {
    display: flex;
    align-items: center;
    gap: 12px;
    justify-content: flex-end;
    .addBtn {
      display: flex;
      justify-content: center;
      width: 20%;
      background-color: #e7effd;
      cursor: pointer;
      border: none;
      outline: none;
      gap: 6px;
      border-radius: 17px;
      padding: 8px 12px;
      font-size: 12px;
      font-weight: 400;
      color: #115eed;
      align-items: center;
      cursor: pointer;
      transition: 0.5s all ease-in-out;
      &:hover {
        background-color: #115eed50;
      }
      .btnAdd {
        background: transparent;
        border: none;
        outline: none;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .btn {
      width: 164px;
      border: 0;
      outline: 0;
      border: 1px solid transparent;
      padding: 12px;
      border-radius: 4px;
      font-size: 14px;
      font-weight: 400;
      background-color: #115eed;
      color: #ffffff;
      cursor: pointer;
      transition: 0.3s ease;
      &:hover {
        border-color: #115eed50;
      }
      &.cancel {
        background-color: #115eed03;
        color: #000000;
      }
    }
  }
}

@media (max-width: 425px) {
  .profilePhoneEdit {
    .btnGroup {
      .addBtn {
        width: 100%;
      }
    }
  }
}
