.profileVideoContainer {
  width: 100%;
  height: 100%;
  display: flex;
  // justify-content: space-between;
  gap: 14px;
  &.toRight {
    justify-content: flex-end;
    gap: 16px;
  }
  .profileSocialMedia {
    width: 100%;
    // min-width: 617px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
    a {
      background-color: #f6f5f8;
      border: 1px solid #115eed03;
      padding: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      transition: 0.3s ease;
      &:hover {
        border-color: #115eed50;
      }
      img {
        width: 24px;
        height: 24px;
        object-fit: cover;
      }
    }
  }
  .videoPlayer {
    max-width: 439px;
    position: relative;
    .editIcon {
      position: absolute;
      top: 8px;
      right: 8px;
      width: 26px;
      height: 26px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #e7effd50;
      padding: 5px;
      transition: 0.3s ease;
      cursor: pointer;
      z-index: 1;
      img {
        width: 16px;
        height: 16px;
        object-fit: cover;
      }
      &:hover {
        background-color: #e7effd;
      }
      & + div {
        max-width: 100% !important;
        max-height: 192px;
      }
    }
    iframe {
      border-radius: 8px;
    }
    .introVideo {
      border-radius: 8px;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 8px;
      }
      .playIcon {
        width: 48px;
        height: 48px;
        position: absolute;
        cursor: not-allowed;
      }
    }
  }
}

@media (max-width: 1024px) {
  .profileVideoContainer {
    flex-direction: column-reverse;
    gap: 16px;
    .videoPlayer {
      margin-top: 15px;
      max-width: 619px;
      max-height: 100%;
      .editIcon {
        & + div {
          width: 100% !important;
          max-height: 128px;
        }
      }
      div {
        width: 100%;
        max-width: 100%;
        max-height: 128px !important;
      }
    }
  }
}

@media (max-width: 468px) {
  .profileVideoContainer {
    .videoPlayer {
      margin-bottom: 20px;
      .editIcon {
        & + div {
          width: 100% !important;
          height: 100% !important;
        }
      }
    }
  }
}
