.settingsPlanCard {
  width: 100%;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  border: 1px solid #e2ecfd;
  background-color: #ffffff;
  transition: 0.3s ease;
  cursor: pointer;
  height: 179px;
  &.selected {
    border-color: #7ca6f5;
  }
  &:hover {
    border-color: #115eed;
  }
  .cardHead {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .planTitle {
      font-size: 16px;
      font-weight: 500;
      color: #115eed;
    }
    .badge {
      border-radius: 8px;
      background: #e7effd;
      padding: 10px;
      font-size: 14px;
      color: #115eed;
      text-align: center;
    }
  }
  .cardBody {
    .price {
      font-size: 24px;
      font-weight: 500;
      color: #23272f;
    }
  }
  .cardFooter {
    display: flex;
    justify-content: space-between;
    .planDesc {
      font-size: 14px;
      color: #535861;
    }
    img {
      width: 23px;
      height: 23px;
      object-fit: contain;
    }
  }
}
