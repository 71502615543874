.planTab {
  padding: 40px 24px;
  border-radius: 4px;
  border: 1px solid #efefef;
  .titles {
    display: flex;
    flex-direction: column;
    gap: 48px;
    margin-bottom: 24px;
    h4 {
      font-size: 18px;
      font-weight: 500;
      color: #000000;
    }
  }
  .plans {
    display: flex;
    margin-bottom: 48px;
    .settingsPlanCard {
      width: 50%;
    }
  }
  .btn {
    border: 0;
    outline: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    background: #115eed;
    font-size: 16px;
    font-weight: 500;
    color: #ffffff;
    padding: 14px 31px;
    cursor: pointer;
    transition: 0.3s ease;
    &:hover {
      opacity: 0.8;
    }
    &:disabled {
      opacity: 0.5;
    }
  }
}

@media (max-width: 768px) {
  .planTab {
    padding: 16px;
    .titles {
      .title {
        display: none;
      }
    }
    .plans {
      flex-direction: column;
      .settingsPlanCard {
        width: 100%;
      }
    }
    .btn {
      width: 100%;
    }
  }
}
