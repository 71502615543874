.profileLangEdit {
  display: flex;
  flex-direction: column;
  .infoLangEdit {
    display: flex;
    flex-direction: column;
    gap: 20px;
    .editBtn {
      display: flex;
      width: 100%;
    }
    .addBtn {
      width: 100%;
      button {
        display: flex;
        justify-content: center;
        background-color: #e7effd;
        border: none;
        outline: none;
        gap: 6px;
        border-radius: 17px;
        padding: 8px 12px;
        font-size: 12px;
        font-weight: 400;
        color: #115eed;
        cursor: pointer;
        transition: 0.5s all ease-in-out;
      }
    }
    .btnGroup {
      display: flex;
      align-items: center;
      gap: 12px;
      justify-content: flex-end;

      .btn {
        width: 100%;
        border: 0;
        outline: 0;
        border: 1px solid transparent;
        padding: 12px;
        border-radius: 4px;
        font-size: 14px;
        font-weight: 400;
        background-color: #115eed;
        color: #ffffff;
        cursor: pointer;
        transition: 0.3s ease;
        &:hover {
          border-color: #115eed50;
        }
        &.cancel {
          background-color: #115eed03;
          color: #000000;
        }
      }
    }
    span {
      color: #535861;
      font-size: 14px;
      font-weight: 400;
    }
    .editSkillsArea {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      .skillsCaption {
        background-color: #f6f5f8;
        border-radius: 20px;
        outline: none;
        border: none;
        display: flex;
        gap: 10px;
        padding: 9px 16px;
        color: #23272f;
        font-size: 14px;
        font-weight: 400;
        img {
          width: 20px;
          height: 20px;
          object-fit: cover;
        }
        span {
          color: #535861;
          cursor: pointer;
        }
      }
    }
    .addSkillsArea {
      display: flex;
      flex-direction: column;
      gap: 10px;
      .infoSkills {
        display: flex;
        gap: 10px;
        font-size: 14px;
        color: #979797;
      }
    }
  }
}

@media (max-width: 768px) {
  .profileLangEdit {
    .infoLangEdit {
      .btnGroup {
        flex-direction: column;
        .editBtn {
          width: 100%;
        }
      }
      .addBtn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        button {
          width: 100%;
        }
      }
      .editSkillsArea {
        flex-wrap: wrap;
        justify-content: center;
      }
    }
  }
}
