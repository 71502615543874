.customCheckbox {
  display: inline-block;
  position: relative;
  width: 20px;
  height: 20px;
  background: #ffffff;
  border: 1.5px solid rgba(17, 94, 237, 0.25);
  border-radius: 4px;
  cursor: pointer;
  .checkMark {
    display: inline-block;
    width: 14px;
    height: 14px;
    background-image: url(../../images/icons/check-blue.svg);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    opacity: 0;
    visibility: hidden;
    transition: 0.3s ease;
    transform: translate(10%, -5%);
  }
  &.checked {
    border-color: #115eed;
    .checkMark {
      visibility: visible;
      opacity: 1;
    }
  }
  input {
    display: none;
  }
}
