@import "../../mixins";

.registerForm {
  width: 100%;
  height: 100%;
  .formTitle {
    font-weight: 500;
    font-size: 32px;
    color: #23272f;
    margin-bottom: 16px;
  }
  .socialBtnGroup {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 16px;
    .btnWithLogo {
      min-width: 188px;
      min-height: 40px;
      outline: 0;
      border: 1px solid #231f2012;
      padding: 10px 12px;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
      background: #ffffff;
      transition: 0.3s ease;
      cursor: pointer;
      &.linkedin {
        background: #0a66c2;
        .text {
          color: #ffffff;
        }
      }
      .icon {
        width: 20px;
        height: 20px;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
      .text {
        font-size: 14px;
        color: #23272f;
      }
      &:hover {
        transform: scale(1.05);
      }
      &:active {
        transform: scale(0.95);
      }
    }
  }
  .question {
    color: #535861;
    a {
      color: #115eed;
      transition: 0.3s ease;
      &:hover {
        opacity: 0.8;
      }
    }
  }
  .socialBtns {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 17px;
    margin: 32px 0;
    a {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
      width: 50%;
      height: 40px;
      background: #ffffff;
      border: 1px solid rgba(35, 31, 32, 0.12);
      border-radius: 4px;
      transition: 0.3s ease;
      &:hover {
        opacity: 0.8;
      }
      &:nth-child(2) {
        background: #1877f2;
        color: #fff;
      }
      img {
        width: 20px;
        height: 20px;
        object-fit: contain;
      }
    }
  }
  .inputBox {
    display: flex;
    flex-direction: column;
    gap: 12px;
    .errorAlert {
      font-size: 14px;
      color: #ed4611;
      position: relative;
      padding-left: 26px;
      &::before {
        content: "";
        background-image: url(../../../images/icons/alert.svg);
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain;
        width: 20px;
        height: 20px;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
    .PhoneInput {
      .PhoneInputInput {
        @include formInput();
        font-size: 16px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        &.err {
          border-color: #ed4611;
        }
      }
      .PhoneInputCountry {
        background: #f2f4f8;
        margin-right: 0;
        padding: 10px;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }
    }
  }
  .formBtn {
    margin-top: 32px;
    @include formBtn();
  }
}

@media (max-width: 768px) {
  .registerForm {
    .socialBtns {
      width: 100%;
      flex-wrap: wrap;
      a {
        width: 100%;
      }
    }
  }
}

@media (max-width: 468px) {
  .registerForm {
    .socialBtnGroup {
      flex-direction: column;
      .btnWithLogo {
        min-width: 100%;
      }
    }
    .formTitle {
      font-size: 24px;
    }
    .question {
      font-size: 14px;
    }
  }
}
