.upgradeFreePack {
  margin-bottom: 54px;
  .upgradePackCaption {
    &:nth-child(1) {
      padding: 0 0 40px;
    }
    &:nth-child(3) {
      padding: 120px 0 61px;
      .text {
        max-width: 437px;
      }
    }
  }
  .packetArea {
    .upgradePackCaption {
      max-width: 491px;
      width: 100%;
    }
  }

  .upgradePackInfo {
    &:nth-child(6) {
      flex-direction: row-reverse;
    }
  }
}

// Responsive
@media (max-width: 1024px) {
  .upgradeFreePack {
    .upgradePackCaption {
      &:nth-child(1) {
        padding: 50px 0 30px;
      }
      &:nth-child(3) {
        padding: 100px 0 50px;
      }
    }

    .upgradePackInfo {
      &:nth-child(6) {
        flex-direction: column-reverse;
      }
    }
  }
}

@media (max-width: 768px) {
  .upgradeFreePack {
    .upgradePackCaption {
      &:nth-child(1) {
        padding: 30px 0;
      }
      &:nth-child(3) {
        padding: 80px 0 30px;
      }
    }
  }
}

@media (max-width: 380px) {
  .upgradeFreePack {
    .upgradePackCaption {
      &:nth-child(1) {
        padding: 30px 0;
      }
      &:nth-child(3) {
        padding: 70px 0 30px;
      }
    }
  }
}
