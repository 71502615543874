.infoEditForm {
  position: relative;
  .aboutTextarea {
    height: 161px;
    border: 1px solid #c9cacd80;
    padding: 18px 24px;
    border-radius: 4px;
    outline: none;
    resize: none;
  }
  .textCounter {
    display: flex;
    justify-content: flex-end;
  }
  .infoTextArea {
    display: flex;
    align-items: center;
    gap: 12px;
    span {
      font-size: 14px;
      color: #979797;
      line-height: 21px;
    }
  }
}
