.statisticCard {
  width: 100%;
  padding: 24px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 24px;
  background: #ffffff;
  border: 1px solid rgba(17, 94, 237, 0.12);
  border-radius: 4px;
  .cardImage {
    width: 56px;
    height: 56px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .cardInfo {
    color: #23272f;
    .cardCount {
      font-weight: 700;
      font-size: 20px;
    }
    .cardDetails {
      color: #535861;
    }
  }
}

@media (max-width: 1200px) {
  .statisticCard {
    padding: 12px;
    gap: 12px;
  }
}
