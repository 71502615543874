// ? Fonts
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

// ! Reset default styles
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
  font-family: "Poppins", sans-serif;
  scroll-behavior: smooth;
  user-select: none;
  color: #23272f;
}

// * Global styles
.container {
  width: 92%;
  margin: 0 auto;
}

.row {
  width: 100%;
  display: flex;
  padding: 26px 56px;
}

.errorLabel {
  font-size: 14px;
  color: #ed4611;
  position: relative;
  padding-left: 26px;
  &::before {
    content: "";
    background-image: url(../images/icons/alert.svg);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    width: 20px;
    height: 20px;
    position: absolute;
    top: 0;
    left: 0;
  }
}

// entry transition class
.preLoadertransition {
  overflow: hidden;
  height: 0;
  z-index: 1;
  background: #000000;
  position: fixed;
  left: 0;
  top: 0;
  // bottom: 0;
  right: 0;
}

// exit transition class
.preLoadertransition2 {
  @extend .preLoadertransition;
  z-index: 5;
}

.error-clr {
  color: #ed4611 !important;
}

.id-disabled {
  opacity: 0.5 !important;
}

// .error-bg-clr {
//   background-color: #ed461130 !important;
// }

.error-border-clr {
  border-color: #ed4611 !important;
}

.successLabel {
  color: #12db63;
  position: relative;
  padding-left: 26px;
  &::before {
    content: "";
    background-image: url(../images/icons/check.svg);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    width: 20px;
    height: 20px;
    position: absolute;
    top: 0;
    left: 0;
  }
}

// * Custom scrollbar
::-webkit-scrollbar {
  display: none; // Hide scrollbar to all website

  width: 16px;
  height: 16px;
}

::-webkit-scrollbar-track {
  border-radius: 100vh;
  background: #edf2f7;
}

::-webkit-scrollbar-thumb {
  background: #cbd5e0;
  border-radius: 100vh;
  border: 3px solid #edf2f7;
}

::-webkit-scrollbar-thumb:hover {
  background: #a0aec0;
}

// Global button style
.primaryBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: #115eed;
  padding: 16px 48px;
  border-radius: 4px;
  color: #fff;
  border: 0;
  outline: 0;
  cursor: pointer;
  transition: 0.3s ease;
  span {
    color: #fff;
    font-style: italic;
    font-weight: 500;
    transition: 0.3s ease;
  }
  &:hover {
    background: #0e4cbe;
  }
}

.experienceImage {
  background: linear-gradient(91.74deg, #a528ff 12.93%, #1d42ce 101.26%);
}

input,
textarea,
select {
  font-size: 16px !important;
}

.saveButton {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  .btn {
    outline: 0;
    border: 0;
    padding: 12px 44.5px;
    font-weight: 500;
    color: #ffffff;
    background-color: #27e574;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 2px;
    transition: 0.3s ease;
    cursor: pointer;
    &:hover {
      opacity: 0.7;
    }
    &:disabled {
      &:hover {
        opacity: 0.5;
      }
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
}
