.addProfileModal {
  width: 100%;
  height: 100vh;
  display: none;
  justify-content: center;
  align-items: flex-start;
  padding-top: 56px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  &.active {
    display: flex;
  }
  .modalOverlay {
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(35, 31, 32, 0.75);
    z-index: 999;
  }
  .modalBox {
    position: relative;
    width: 389px;
    background: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 24px;
    border-radius: 8px;
    z-index: 1000;
    .seperator {
      display: none;
      width: 56px;
      height: 4px;
      background: #c3c5d4;
      border-radius: 7px;
      margin: 0 auto 16px auto;
    }
    .addProfileHead {
      .modalTitle {
        font-weight: 500;
        font-size: 16px;
        color: #23272f;
        margin-bottom: 8px;
        margin-right: 16px;
        span {
          font-size: 14px;
          color: #6c7c8f;
        }
      }
      .close {
        display: none;
        width: 24px;
        height: 24px;
        cursor: pointer;
        transition: 0.3s ease;
        &:hover {
          opacity: 0.8;
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
    .usersList {
      width: 100%;
      margin-bottom: 16px;
      display: flex;
      gap: 12px;
      padding: 8px 0;
      overflow-x: auto;
      overflow-y: hidden;
      transition: 0.3s ease;
      &::-webkit-scrollbar {
        height: 12px;
      }
      .userCard {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 4px;
        padding: 2px 12px 2px 2px;
        background: rgba(108, 124, 143, 0.12);
        border-radius: 14px;
        white-space: nowrap;
        .userImg {
          width: 24px;
          height: 24px;
          border-radius: 50%;
          background-color: #1155ed;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 50%;
          }
        }
        .userName {
          font-size: 12px;
          color: #23272f;
        }
      }
    }
    .generalUsers {
      max-height: 441px;
      overflow-y: auto;
      overflow-x: hidden;
      padding-right: 5px;
      &::-webkit-scrollbar {
        width: 10px;
      }
      .boxName,
      .groupName {
        font-weight: 500;
        font-size: 14px;
        color: #23272f;
        margin-bottom: 12px;
      }
      .groupName {
        margin-top: 24px;
      }
      .usersInBox {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 12px;
        .addUserCard {
          width: 100%;
          background: #ffffff;
          border: 1px solid rgba(17, 94, 237, 0.25);
          border-radius: 4px;
          padding: 8px 16px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          transition: 0.3s ease;
          &:hover {
            border-color: #1155ed;
          }
          &.selected {
            border-color: #1155ed;
          }
          .left {
            display: flex;
            align-items: center;
            gap: 12px;
            .avatar {
              width: 56px;
              height: 56px;
              min-width: 56px;
              min-height: 56px;
              border-radius: 100%;
              position: relative;
              &.isDefault {
                display: flex;
                justify-content: center;
                align-items: center;
                img {
                  border-radius: 0;
                }
              }
              .defaultIcon {
                width: 24px;
                height: 24px;
                object-fit: contain;
              }
              &.avatarPro {
                border: 2px solid #a528ff;
              }
              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: top center;
                border-radius: 100%;
              }
              .badge {
                position: absolute;
                bottom: 0;
                right: 0;
                padding: 2px 6px;
                border-radius: 8px;
                background: linear-gradient(
                  91.74deg,
                  #a528ff 12.93%,
                  #1d42ce 101.26%
                );
                color: #ffffff;
                font-size: 11px;
                font-weight: 500;
                &.defaultBadgePosition {
                  padding: 2px 4px;
                }
              }
            }
            // .profileImg {
            //   width: 32px;
            //   height: 32px;
            //   border-radius: 50%;
            //   background-color: #1155ed;
            //   img {
            //     width: 100%;
            //     height: 100%;
            //     object-fit: cover;
            //     border-radius: 50%;
            //   }
            // }
            .userInfo {
              color: #23272f;
              height: auto !important;
              .fullName {
                font-weight: 500;
              }
              .phone {
                font-size: 14px;
                transition: 0.3s ease;
                &:hover {
                  color: #1155ed;
                }
              }
            }
          }
        }
      }
    }
    .errorMsg {
      color: #ed4611;
      font-size: 14px;
      font-weight: 500;
      text-align: center;
      margin-top: 10px;
    }
    .addBtn {
      border: 0;
      outline: 0;
      padding: 16px 83px;
      background: #115eed;
      border-radius: 4px;
      font-weight: 500;
      color: #ffffff;
      margin-top: 12px;
      transition: 0.3s ease;
      border: 1px solid transparent;
      text-align: center;
      cursor: pointer;
      &:hover {
        border-color: #1155ed;
        background-color: #fff;
        color: #1155ed;
      }
    }
  }
  .closeModal {
    margin-top: 8px;
    margin-left: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 4px 12px 4px 4px;
    background: #f6f5f8;
    border-radius: 16px;
    cursor: pointer;
    transition: 0.3s ease;
    z-index: 1000;
    &:hover {
      opacity: 0.8;
    }
  }
}

@media (max-width: 768px) {
  .addProfileModal {
    display: block;
    height: auto;
    padding-top: 0;
    &.active {
      .modalOverlay {
        opacity: 1;
        visibility: visible;
      }
      .modalBox {
        opacity: 1;
        visibility: visible;
        transform: translateY(0);
      }
    }
    .modalOverlay {
      opacity: 0;
      visibility: hidden;
      transition: 0.3s ease;
    }
    .modalBox {
      padding: 16px;
      position: fixed;
      bottom: 0;
      width: 100%;
      border-radius: 20px 20px 0 0;
      transform: translateY(100%);
      opacity: 0;
      visibility: hidden;
      transition: 0.3s ease;
      .seperator {
        display: block;
      }
      .addProfileHead {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .close {
          display: block;
        }
      }
      .generalUsers {
        .usersInBox {
          .addUserCard {
            padding: 8px;
            .left {
              .userInfo {
                height: auto !important;
                .fullName {
                  font-size: 14px;
                }
                .phone {
                  font-size: 12px;
                }
              }
            }
          }
        }
      }
      .errorMsg {
        font-size: 12px;
      }
      .addBtn {
        width: 100%;
        padding: 16px 10px;
        background: #3e3ef4;
        border-radius: 120px;
      }
    }
    .closeModal {
      display: none;
    }
  }
}
