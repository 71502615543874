.paymentsTab {
  width: 100%;
  padding: 40px 24px;
  border-radius: 4px;
  border: 1px solid #efefef;
  background: #ffffff;
  .paymentsTabHead {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .title {
      font-size: 18px;
      font-weight: 500;
      color: #000000;
    }
    .btn {
      outline: 0;
      border: 1px solid transparent;
      display: flex;
      gap: 6px;
      justify-content: center;
      align-items: center;
      padding: 8px 12px;
      border-radius: 17px;
      background: #e7effd;
      cursor: pointer;
      transition: 0.3s ease;
      span {
        font-size: 14px;
        color: #115eed;
      }
      img {
        width: 16px;
        height: 16px;
        object-fit: contain;
      }
      &:hover {
        border-color: #115eed;
      }
      &:disabled {
        opacity: 0.5;
      }
    }
  }
  .cards {
    margin: 48px 0;
    display: flex;
    flex-direction: column;
  }
  .paymentHistory {
    .historyHead {
      display: flex;
      justify-content: space-between;
      align-items: center;
      h2 {
        font-size: 14px;
        font-weight: 500;
        color: #000000;
      }
      .btn {
        outline: 0;
        padding: 10.5px 19px;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 9px;
        border: 1px solid #cbddeb;
        background-color: #ffffff;
        cursor: pointer;
        transition: 0.3s ease;
        img {
          width: 20px;
          height: 20px;
          object-fit: contain;
        }
        span {
          font-size: 14px;
          font-weight: 500;
          color: #535861;
        }
        &:hover {
          border-color: #115eed;
          background-color: #cbddeb;
        }
      }
    }
    .historyBody {
      table {
        width: 100%;
        border-top: 1px solid #cbddeb;
        border-collapse: collapse;
        margin-top: 16px;
        thead {
          tr {
            th {
              color: #212235e5;
              font-size: 12px;
              font-weight: 500;
              border: 1px solid #cbddeb;
              padding: 18px 32px;
              text-align: center;
              &:last-child {
                border-right: 0;
              }
              &:first-child {
                border-left: 0;
              }
            }
          }
        }
        tbody {
          tr {
            td {
              color: #212235e5;
              font-size: 12px;
              font-weight: 500;
              padding: 18px 32px;
              text-align: center;
              .badge {
                border-radius: 2px;
                font-size: 12px;
                font-weight: 500;
                background: #6effa817;
                color: #53da89;
                padding: 6px 13px;
                text-align: center;
                &.failed {
                  background: #ed461117;
                  color: #ed4611;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .paymentsTab {
    padding: 16px;
    .paymentsTabHead {
      .title {
        font-size: 16px;
      }
    }
    .cards {
      margin: 31px 0;
      flex-direction: column;
    }
    .paymentHistory {
      .historyHead {
        .btn {
          padding: 5.5px 8px;
        }
      }
      .historyBody {
        width: 100%;
        overflow-y: scroll;
        table {
          thead {
            tr {
              th {
                padding: 9px 16px;
              }
            }
          }
          tbody {
            tr {
              td {
                padding: 9px 16px;
                .badge {
                  padding: 3px 8px;
                }
              }
            }
          }
        }
      }
    }
  }
}
