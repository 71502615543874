.socialInp {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  height: 100%;
  width: 100%;
  .customInput {
    width: 80%;
  }
  .deleteBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    color: #535861;
    transition: 0.3s ease;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      transform: scale(2);
      transition: 0.3s ease;
    }
    &:hover {
      img {
        transform: scale(2.1);
      }
    }
  }
}

.profileSocialEdit {
  width: 100%;
  .infoSocialForm {
    display: flex;
    flex-direction: column;
    gap: 23px;
    max-height: 500px;
    overflow-y: auto;
    padding-bottom: 32px;
    width: 100%;
    overflow-x: hidden;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .addBtn {
    width: 50%;
    .btnAdd {
      width: 100%;
      max-width: 164px;
      min-width: 164px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #e7effd;
      border: none;
      outline: none;
      gap: 6px;
      border-radius: 17px;
      padding: 8px 12px;
      font-size: 12px;
      font-weight: 400;
      color: #115eed;
      cursor: pointer;
      transition: 0.5s all ease-in-out;
      &:hover {
        background-color: #115eed50;
      }
    }
  }
  .btnGroup {
    display: flex;
    align-items: center;
    gap: 12px;
    justify-content: flex-end;
    .btn {
      width: 164px;
      border: 0;
      outline: 0;
      border: 1px solid transparent;
      padding: 12px;
      border-radius: 4px;
      font-size: 14px;
      font-weight: 400;
      background-color: #115eed;
      color: #ffffff;
      cursor: pointer;
      transition: 0.3s ease;
      &:hover {
        border-color: #115eed50;
      }
      &.cancel {
        background-color: #115eed03;
        color: #000000;
      }
      &:disabled {
        opacity: 0.5;
      }
    }
  }
}

@media (max-width: 425px) {
  .profileSocialEdit {
    .infoSocialForm {
      .addBtn {
        width: 100%;
        .btnAdd {
          width: 100%;
        }
      }
    }
    .btnGroup {
      justify-content: center;
      flex-direction: column;
      .addBtn {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .btn {
        width: 100%;
      }
    }
  }
}
